<div class="regenerate-wrapper">
  <app-header></app-header>
  <app-breadcrum
    [isBack]="true"
    (onClick)="this.router.navigate(['/'])"
    [content]="'Regenerate'"
  ></app-breadcrum>
  <div class="body-wrapper">
    <div class="obj-wrapper">
      <div #myObject class="myObject"></div>
    </div>
    <div class="btn-wrapper">
      <div class="btn-group" *ngIf="!isRegenerated">
        <div class="button">
          <button
            (click)="handleClick()"
            class="btn btn-primary"
            color="primary"
          >
            <label for="file" class="lable-icon"> Upload</label>
            <input
              id="file"
              class=""
              type="file"
              (change)="fileChanged($event)"
              accept=".obj"
              hidden
            />
          </button>
        </div>
        <!-- <div class="button">
          <button class="btn btn-primary" color="primary">Search</button>
        </div> -->

        <div class="button" (click)="openModal(false)">
          <button
            btnHighlight
            label="Save"
            [hidden]="!isSaved"
            color="#ffffff"
            bgColor="#000000"
            [isLoading]="isSavedLoading"
          ></button>
        </div>
        <div class="button">
          <button
            btnHighlight
            [hidden]="!isSaved"
            label="Regenerate"
            [bgColor]="colors.INK_MAIN"
            [color]="colors.WHITE"
            [isLoading]="isRegeneratedLoading"
            (click)="regenerate()"
          ></button>
        </div>
      </div>
      <div *ngIf="isRegenerated" class="btn-group btn-group-reset">
        <div class="button" (click)="switch(!isFaced)">
          <button
            btnHighlight
            [label]="
              isFaced
                ? ' Switch to  recontructed face'
                : 'Switch to wound split'
            "
            [bgColor]="colors.INK_MAIN"
            [color]="colors.WHITE"
          ></button>
        </div>
        <div class="button">
          <button
            btnHighlight
            label="Save"
            [bgColor]="colors.INK_MAIN"
            [color]="colors.WHITE"
            (click)="openModal(true)"
          ></button>
        </div>
        <div class="button">
          <button
            btnHighlight
            label="New regeneration"
            [bgColor]="colors.INK_MAIN"
            [color]="colors.WHITE"
            (click)="back()"
          ></button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="result-wrapper" *ngIf="!isRegenerated">
    <div class="obj-wrapper">
      <div #myObject1 class="myObject1"></div>
    </div>
    <div class="obj-wrapper">
      <div #myObject2 class="myObject2"></div>
    </div>
  </div> -->
</div>
