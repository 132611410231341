export enum Colors {
    PRIMARY_LIGHT = '#F8A544',
    PRIMARY_MAIN = '#F8A544',
    PRIMARY_DARK = '#F8A544',

    SECONDARY_LIGHT = '#3F3F3F',
    SECONDARY_MAIN = '#3F3F3F',
    SECONDARY_DARK = '#3F3F3F',

    SKY_LIGHT = '#3F3F3F',
    SKY_MAIN = '#FAFAFA',
    SKY_DARK = '#3F3F3F',

    INK_LIGHT = '#3F3F3F',
    INK_MAIN = '#3F3F3F',
    INK_DARK = '#3F3F3F',

    ERROR = '#ef5350',
    WARN = '#ff9800',
    WHITE = '#ffffff'


}