import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandlerService } from './http-error-handler.service';
import { Observable } from 'rxjs';
import { environment } from 'environment';




const PRINTING_API = environment.api + 'api/activities'

const headers = new HttpHeaders()
  .set('Access-Control-Allow-Origin', '*');
@Injectable({
  providedIn: 'root'
})

export class PrintingService {
  private handleError: HandleError;

  constructor(private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService) {
    this.handleError = httpErrorHandler.createHandleError('PrintingService');

  }


  //post to print
  print(formData: any): Observable<any> {
    return this.http.post<any>(PRINTING_API, formData)

  }
  getIntentTime(formData: any): Observable<any> {
    return this.http.post<any>(environment.api + 'slice-gcode', formData)


  }


}
