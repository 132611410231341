import { Component, ViewChild, Inject } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { delay, interval } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerService } from '../../services/spinner.service';


@Component({
  selector: 'app-ai-process-dialog',
  templateUrl: './ai-process-dialog.component.html',
  styleUrls: ['./ai-process-dialog.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  standalone: true,
  imports: [
    MatStepperModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule
  ],
})
export class AiProcessDialogComponent {

  @ViewChild('stepper') myStepper!: MatStepper;

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  thirdFormGroup = this._formBuilder.group({
    thirdCtrl: ['', Validators.required],
  });
  showSpinner = true;
  isRegenerate = false;


  constructor(private _formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AiProcessDialogComponent>,
    private spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {

    spinnerService.spinner$.subscribe((data: boolean) => {
      setTimeout(() => {
        this.showSpinner = data ? data : false;
      })
    });
    this.isRegenerate = data.isRegenerate != undefined ? data.isRegenerate : false;
  }
  ngAfterViewInit() {
    // console.log(this.myStepper.steps['_results'][0]['_stepper']);

    let currentIdx = this.myStepper['_selectedIndex'];
    let length = this.myStepper.steps['_results'].length;
    let currentStep = this.myStepper.steps['_results'][currentIdx]['_stepper'];
    var x = 0;

    this.setIntervalX(() => {
      if (currentIdx == length - 3) {
      } else this.goForward(currentStep);

      // Your logic here

    }, 1600, 5);

  }
  setIntervalX(callback: any, delay: any, repetitions: any) {
    var x = 0;
    var intervalID = window.setInterval(() => {

      callback();
      if (this.myStepper['_selectedIndex'] == this.myStepper.steps['_results'].length - 1 && this.showSpinner == false) {
        this.dialogRef.close();
      }
    }, delay);
  }
  goBack(stepper: MatStepper) {
    stepper.previous();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  goForward(stepper: MatStepper) {
    stepper.next();
  }
}
