<div *ngIf="!showSpinner" class="inventory-page-wrapper">
  <app-header></app-header>
  <app-breadcrum
    [isBack]="true"
    (onClick)="this.router.navigate(['/'])"
    [content]="'Inventory'"
  ></app-breadcrum>
  <div class="inventory-page-body">
    <mat-grid-list cols="6" rowHeight="5:3" [gutterSize]="'1em'">
      <mat-grid-tile [colspan]="2" [rowspan]="4">
        <div class="project-list-wrapper">
          <div class="tree-title">
            <h2 class="title">Project Tree</h2>

            <div class="">
              <button
                (click)="openCreateFolderDialog(true)"
                class="project-btn"
                [disabled]="projects.length == 0"
              >
                Create project
              </button>
            </div>
          </div>

          <div class="project-list" *ngIf="projects.length > 0">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
              <!-- Tree node template for leaf nodes( no child) -->
              <mat-tree-node
                id="{{ node.n.id }}"
                (click)="getProjectContent(node.n, node)"
                *matTreeNodeDef="let node"
                matTreeNodePadding
              >
                <div class="more-action-button">
                  <button
                    #clickRightMenuTrigger
                    mat-icon-button
                    [matMenuTriggerFor]="rightMenu"
                    [matMenuTriggerData]="{ item: node.n }"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu
                    [hasBackdrop]="true"
                    #rightMenu="matMenu"
                    xPosition="before"
                  >
                    <ng-template
                      matMenuContent
                      let-item="item"
                      let-parent="parent"
                    >
                      <button
                        (click)="editProjectTree(item, parent)"
                        mat-menu-item
                      >
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                      </button>
                      <button (click)="deleteProjectTree(item)" mat-menu-item>
                        <mat-icon>delete_outline</mat-icon>
                        <span>Delete</span>
                      </button>
                    </ng-template>
                  </mat-menu>
                </div>
                <!-- use a disabled button to provide padding for tree leaf -->
                <button disabled class="mat-btn" mat-icon-button></button>
                <img
                  *ngIf="node.level != 0"
                  class="folder-icon"
                  src="../../../assets/images/folder-icon.png"
                  alt=""
                />
                <img
                  *ngIf="node.level == 0"
                  class="folder-icon"
                  src="../../../assets/images/project-icon.png"
                  alt=""
                  width="30px"
                />

                {{ node.name }}
              </mat-tree-node>
              <!-- Tree node template for expandable nodes -->
              <mat-tree-node
                (click)="getProjectContent(node.n, node)"
                id="{{ node.n.id }}"
                *matTreeNodeDef="let node; when: hasChild"
                matTreeNodePadding
              >
                <div class="more-action-button">
                  <button
                    #clickRightMenuTrigger
                    mat-icon-button
                    [matMenuTriggerFor]="rightMenu"
                    [matMenuTriggerData]="{
                      item: node.n,
                      parent: currentChildrens
                    }"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu
                    [hasBackdrop]="true"
                    #rightMenu="matMenu"
                    xPosition="before"
                  >
                    <ng-template
                      matMenuContent
                      let-item="item"
                      let-parent="parent"
                    >
                      <button
                        (click)="editProjectTree(item, parent)"
                        mat-menu-item
                      >
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                      </button>
                      <button (click)="deleteProjectTree(item)" mat-menu-item>
                        <mat-icon>delete_outline</mat-icon>
                        <span>Delete</span>
                      </button>
                    </ng-template>
                  </mat-menu>
                </div>
                <button
                  mat-icon-button
                  matTreeNodeToggle
                  [attr.aria-label]="'Toggle ' + node.name"
                >
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{
                      treeControl.isExpanded(node)
                        ? "expand_more"
                        : "chevron_right"
                    }}
                  </mat-icon>
                </button>

                <img
                  *ngIf="node.level != 0"
                  class="folder-icon"
                  src="../../../assets/images/folder-icon.png"
                  alt=""
                />
                <img
                  *ngIf="node.level == 0"
                  class="folder-icon"
                  src="../../../assets/images/project-icon.png"
                  alt=""
                  width="30px"
                />

                {{ node.name }}
              </mat-tree-node>
            </mat-tree>
          </div>
          <div class="project-list-wrapper" *ngIf="projects.length == 0">
            0 project found
          </div>
          <div class="center-btn">
            <button
              (click)="openCreateFolderDialog()"
              class="btn"
              [disabled]="projects.length == 0"
            >
              Create folder
            </button>
          </div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="4"
        ><div class="project-content-wrapper">
          <h2 class="title">
            Content
            <div class="progress-bar" *ngIf="loaderService.isLoading | async">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
          </h2>

          <div class="search-wrapper">
            <app-search (searchEvent)="getSearchVal($event)"></app-search>
          </div>
          <!-- loader goes here -->
          <!-- Total {{ tableDataSource }} file -->
          <div class="wrapper">
            <mat-grid-list cols="9" rowHeight="1:0.6" class="data-wrapper">
              <!-- for upload new file -->
              <mat-grid-tile class="upload-item" colspan="9">
                <div class="upload-wrapper">
                  <form
                    [formGroup]="uploadForm"
                    (change)="onSubmit(currentProjectNode)"
                  >
                    <input
                      name="file"
                      id="file"
                      type="file"
                      (change)="handleFileInput($event)"
                      accept=".obj"
                      formControlName="file"
                      ngModel
                      hidden
                    />
                  </form>
                  <button
                    class="upload-button"
                    [disabled]="this.projects.length == 0"
                    onclick="document.getElementById('file').click();"
                  >
                    <mat-icon [ariaDisabled]="true">upload</mat-icon>
                  </button>

                  <div class="name data-content">
                    <div>Upload new file</div>
                    <div class="sub">(maxsize 20MB)</div>
                  </div>
                </div></mat-grid-tile
              >
            </mat-grid-list>

            <table
              *ngIf="fileDataToDisplay && fileDataToDisplay.length > 0"
              mat-table
              matSort
              [dataSource]="tableDataSource"
              class="mat-elevation-z8 demo-table"
            >
              <ng-container
                *ngFor="let column of columns"
                [matColumnDef]="column.columnDef"
              >
                <ng-container
                  *ngIf="
                    column.columnDef != 'action' && column.columnDef != 'image';
                    else noSort
                  "
                >
                  <th
                    mat-sort-header
                    mat-header-cell
                    *matHeaderCellDef
                    class="data-content"
                  >
                    <strong>{{ column.header }}</strong>
                  </th>
                </ng-container>
                <ng-template #noSort>
                  <th mat-header-cell *matHeaderCellDef class="data-content">
                    <strong>{{ column.header }}</strong>
                  </th>
                </ng-template>

                <div *ngIf="column.columnDef == 'image'; else elseBlock">
                  <td
                    class="data-content img-content"
                    mat-cell
                    *matCellDef="let row"
                    (click)="getFilePreview(row)"
                  >
                    <img
                      src="../../../assets/images/model-preview.png"
                      alt=""
                    />
                  </td>
                </div>
                <div *ngIf="column.columnDef == 'action'; else elseBlock">
                  <td class="app" mat-cell *matCellDef="let row">
                    <button
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" xPosition="before">
                      <button mat-menu-item (click)="openEditFileDialog(row)">
                        <mat-icon>edit</mat-icon>
                        <span>Rename</span>
                      </button>
                      <button mat-menu-item (click)="deleteFile(row)">
                        <mat-icon>delete_outline</mat-icon>
                        <span>Delete</span>
                      </button>
                      <button mat-menu-item>
                        <mat-icon>cloud_download</mat-icon>
                        <span
                          ><a
                            style="text-decoration: none; color: black"
                            [href]="row.url"
                            download="test.obj"
                            >Download</a
                          ></span
                        >
                      </button>
                    </mat-menu>
                  </td>
                </div>
                <ng-template #elseBlock>
                  <td mat-cell *matCellDef="let row" class="data-content">
                    {{ column.cell(row) }}
                  </td></ng-template
                >
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator
              style-paginator
              [pageSize]="5"
              #paginator
              [length]="fileDataToDisplay.length"
              [pageSizeOptions]="[5]"
              [showFirstLastButtons]="true"
              aria-label="select page"
            >
            </mat-paginator>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <app-spinner *ngIf="showSpinner"></app-spinner>
</div>
