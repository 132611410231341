<div class="container">
  <div class="header-wrapper">
    <div class="left-side-wrapper">
      <div class="logo-wrapper" routerLink="/">
        <img src="../../../assets/images/Logo.png" alt="Logo" />
      </div>
      <div class="app-name-wrapper" routerLink="/">Horizon Tek</div>
    </div>
    <div class="right-side-wrapper">
      <div class="not-authen-wrapper" *ngIf="user == null">
        <div class="button">
          <button
            btnHighlight
            label="Register"
            bgColor="#ffffff"
            color="#000000"
            routerLink="/register"
          ></button>
        </div>
        <div class="button">
          <button
            btnHighlight
            label="Login"
            color="#ffffff"
            bgColor="#000000"
            routerLink="/login"
          >
            Login
          </button>
        </div>
      </div>
      <div class="not-authen-wrapper" *ngIf="user != null">
        <div class="account-name-wrapper">{{ user?.firstName == "" ? "Not yet" : user?.firstName }}</div>
        <div class="button info">
          <div class="profile-image">
            <div
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <img
                [src]="user?.avatar_url"
                onerror="this.src='../../../../assets/images/avatar.png'"
                alt="Profile"
              />
              <mat-icon>expand_more</mat-icon>
            </div>
          </div>
          <mat-menu #menu="matMenu">
            <button (click)="navigateToProfile()" mat-menu-item>
              <mat-icon>person</mat-icon>
              <span>Profile</span>
            </button>
            <button (click)="logout()" mat-menu-item>
              <mat-icon>subdirectory_arrow_right</mat-icon>
              <span>Logout</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>
