import { Observable } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtService } from './jwt.service';
import { SnackbarService } from './snackbar.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    // public jwt$!: Observable<string>;
    constructor(private jwtService: JwtService, private router: Router, private snackbarService: SnackbarService
    ) { }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const token = this.jwtService.getToken();
        if (token != undefined) {
            return true;
        } else {
            this.snackbarService.open('You need to login first', "Hide")
            this.router.navigate(['']);
            return false;
        }



    }
}