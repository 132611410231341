import { FlatTreeControl } from '@angular/cdk/tree';
import { Component } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Folder } from '../../models/folder.model';
import { InventoryService } from '../../services/inventory.service';
import { Project } from '../../models/project.model';
import { JwtService } from '../../services/jwt.service';




interface ProjectNode {
  id: string;
  name: string;
  files?: File[];
  children?: Folder[];
  parentId?: any;
  projectId?: any;
}
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  n: ProjectNode
}

@Component({
  selector: 'app-folder-dialog',
  templateUrl: './folder-dialog.component.html',
  styleUrls: ['./folder-dialog.component.scss']
})
export class FolderDialogComponent {
  private _transformer = (node: ProjectNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      n: node
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  projects: Project[] = [];
  currentProject!: ProjectNode;//selected project
  currentProjectNode: ProjectNode[] = [];
  currentNode!: ProjectNode;
  folderId!: string;
  projectId!: string;

  constructor(
    public dialogRef: MatDialogRef<FolderDialogComponent>,
    private inventoryService: InventoryService,
    private jwt: JwtService
  ) {
    this.dataSource.data = [];
  }
  ngOnInit() {
    this.getAllProjects();

  }
  //tree functions
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  getAllProjects(): void {
    this.inventoryService.getAllProjects().subscribe(data => {
      this.projects = data.data.filter((i: any) => i.userId == this.jwt.getUser().id);

      this.currentProject = {
        id: data.data[0].id,
        name: data.data[0].name,
        files: data.data[0].file,
        children: data.data[0].folder
      }

      this.projects.map(p => {
        var folderArr!: Folder[];
        var fileArr!: File[];
        this.inventoryService.getProjectById(p.id).subscribe(res => {
          folderArr = res.data.folder;
          fileArr = res.data.files;
          this.currentProjectNode.push(Object.assign({}, { id: p.id, name: p.name, files: fileArr, children: folderArr }));

          this.dataSource.data = this.currentProjectNode

          this.getProjectContent(this.currentProjectNode as any)

          let defaultProject = this.currentProjectNode[0];
          this.getProjectContent({
            id: defaultProject.id,
            name: defaultProject.name,
            files: defaultProject.files,
            children: defaultProject.children
          })
        })
      }
      )
    });
  }

  //get single project folders & files
  getProjectContent(node: ProjectNode, id?: string): void {
    // const ele = document.getElementsByClassName('active-node')[0] as HTMLElement
    // ele.style.background = "#FFFFFF"
    document.getElementsByClassName('active-node')[0]?.classList.remove('active-node')
    document.getElementById(node.id)?.classList.add('active-node')

    this.currentNode = node;
    this.currentProject = node;

    if (Object.keys(this.currentNode).length > 4) {
      this.folderId = this.currentNode.id

    } else {
      this.projectId = this.currentProject.id;
    }

  }


  //DIALOG
  onNoClick(): void {
    this.dialogRef.close({ data: {}, isSaved: false });
  }
}
