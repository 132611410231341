import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  title: string = 'Dialog';
  btnContent: string = 'Delete';
  content: string = 'Are you sure?';
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    console.log(data);
    this.title = data.title;
    this.btnContent = data.btnContent;
    this.content = data.content;

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
