import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, of, map } from 'rxjs';
import { User } from '../models/user.model';
import { environment } from 'environment';
import { JwtService } from './jwt.service';

const AUTH_API = environment.api + 'api/auth/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private jwt: JwtService) { }

  login(credentials: any): Observable<any> {
    return this.http.post(AUTH_API + 'login', {
      username: credentials.username,
      password: credentials.password
    }, httpOptions);
  }

  register(user: any): Observable<any> {

    return this.http.post(AUTH_API + 'register', {
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
      email: user.email,
      password: user.password
    }, httpOptions);
  }
  getUserById(id: string): Observable<any> {
    return this.http.get<any>(environment.api + `api/users/${id}`)
  }
  updateProfile(body: User): Observable<any> {
    return this.http.put<any>(environment.api + `api/users/${body.id}`, body)
  }
  updateProfileAvatar(formData: any): Observable<any> {
    let userId = this.jwt.getUser().id;
    return this.http.patch<any>(environment.api + `api/users/${userId}/avatar`, formData)
  }

  //forgot password
  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(AUTH_API + 'forgot-password', { email: email })
  }
  resetPassword(resetToken: string, password: string): Observable<any> {
    return this.http.patch<any>(AUTH_API + 'reset-password/' + resetToken, { password: password })
  }

  //change password
  changePassword(currentPassword: string, newPassword: string): Observable<any> {
    console.log('alo', this.jwt.getUser());
    let userId = this.jwt.getUser().id;
    return this.http.patch<any>(environment.api + `api/users/${userId}/password`, {
      oldPassword: currentPassword,
      newPassword: newPassword
    })
  }
}