import { BehaviorSubject, Observable } from 'rxjs';

import { Component } from '@angular/core';
import { User } from 'src/app/core/models/user.model';
import { JwtService } from 'src/app/core/services/jwt.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {
  user!: User | null;
  constructor(private jwtService: JwtService) {
    this.user = this.jwtService.getUser()
  }


  ngDoCheck() {
    this.user = this.jwtService.getUser()
  }

}
