import { Component } from '@angular/core';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { MaterialLoader } from 'three';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { FolderDialogComponent } from 'src/app/core/layout/folder-dialog/folder-dialog.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InventoryService } from 'src/app/core/services/inventory.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FaceRegenerateService } from 'src/app/core/services/face-regenerate.service';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/core/services/loader.service';
import { Colors } from '../../../my-pallette'
import { SnackbarService } from 'src/app/core/services/snackbar.service';

import { saveAs } from "file-saver";
import { AiProcessDialogComponent } from 'src/app/core/layout/ai-process-dialog/ai-process-dialog.component';
import { SpinnerService } from 'src/app/core/services/spinner.service';

@Component({
  selector: 'app-regenerate-page',
  templateUrl: './regenerate-page.component.html',
  styleUrls: ['./regenerate-page.component.scss']
})
export class RegeneratePageComponent {

  //upload file
  file!: any;
  fileName: string = "";
  localUrl!: any;
  url!: any;
  f!: File;

  //upload file form
  uploadForm!: FormGroup;
  fileUrl!: string;
  isSaved = false;
  isSavedLoading = false;
  isRegeneratedLoading = false;
  showSpinner = true;


  colors = Colors;

  isRegenerated = false;
  reconstruction_face_link: string = '';
  peace_link: string = '';

  fileRegeneration!: File;
  fileFillingPart!: File;
  isFaced = false;

  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private _fb: FormBuilder,
    private inventoryService: InventoryService,
    private snackbarService: SnackbarService,
    private regenerateService: FaceRegenerateService,
    public router: Router,
    public loaderService: LoaderService,
    public spinnerService: SpinnerService
  ) {
    this.spinnerService.spinner$.subscribe((data: boolean) => {
      setTimeout(() => {
        this.showSpinner = data ? data : false;
      });
    });
  }
  ngOnInit() {
    //upload form
    this.uploadForm = this._fb.group({
      file: [''],
      projectId: [''],
      folderId: ['']
    })


  }

  handleClick() {
    const input = document.getElementById('file');
    input?.addEventListener('click', function fileChanged(event) {

    });
  }
  fileChanged(e: any) {
    this.file = e.target.files[0];
    this.fileName = this.file.name;
    this.localUrl = e.target.value;

    this.uploadForm.get('file')?.setValue(this.file)

    this.loadObjFile();
    this.isSaved = true
  }
  public loadObjFile() {

    var start = new Date().getTime();
    //create new file
    while (document.querySelectorAll('canvas').length != 0) {
      document.getElementsByTagName('canvas')[0].remove();
    }

    var scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);
    var camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight / 1.35, 1, 1000);
    camera.position.z = 720;
    camera.position.x = 0;
    camera.position.y = 0;


    var renderer = new THREE.WebGLRenderer();
    renderer.setSize(750, 450);

    const container = document.getElementsByClassName('myObject')[0];
    container?.appendChild(renderer.domElement);

    var can = document.querySelectorAll('canvas')[0];
    can!.style.position = 'relative';
    can!.style.top = "1rem";
    can!.style.left = "0.5rem";


    var controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.enableZoom = true;

    var keyLight = new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 75%)'), 1.0);
    var keyLight2 = new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 55%)'), 1.0);
    var keyLight3 = new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 55%)'), 1.0);
    var keyLight4 = new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 55%)'), 1.0);
    var keyLight5 = new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 55%)'), 1.0);
    var keyLight6 = new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 75%)'), 1.0);
    // keyLight.position.set(-50, 0, 100);
    keyLight.position.set(1, 0, 0);
    scene.add(keyLight);
    keyLight2.position.set(0, 1, 0);
    scene.add(keyLight2);
    keyLight3.position.set(0, 0, 1);
    scene.add(keyLight3);
    keyLight4.position.set(-1, 0, 0);
    scene.add(keyLight4);
    keyLight5.position.set(0, -1, 0);
    scene.add(keyLight5);
    keyLight6.position.set(0, 0, -1);
    scene.add(keyLight6);


    var objLoader = new OBJLoader();


    //read file
    const fileReader = new FileReader();
    let fileString: string = "";
    fileReader.onloadend = () => {
      var reader = fileReader.result + "";
      fileString = reader;
      this.f = new File([reader], 'src.obj');
      const url = window.URL.createObjectURL(this.f);
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      objLoader.load(this.url.changingThisBreaksApplicationSecurity, function (object: any) {
        scene.add(object);
        object.position.y = 20;

      });
    }
    fileReader.readAsText(this.file)

    var animate = function () {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };
    animate();
  }
  public loadObjFileFromApi(link: string) {
    //create new file
    while (document.querySelectorAll('canvas').length != 0) {
      document.getElementsByTagName('canvas')[0].remove();
    }

    var scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);
    var camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight / 1.35, 1, 1000);
    camera.position.z = 720;
    camera.position.x = 0;
    camera.position.y = 0;

    var renderer = new THREE.WebGLRenderer();
    renderer.setSize(750, 450);

    const container = document.getElementsByClassName('myObject')[0];
    container?.appendChild(renderer.domElement);

    var can = document.querySelectorAll('canvas')[0];
    can!.style.position = 'relative';
    can!.style.top = "1rem";
    can!.style.left = "0.5rem";


    var controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.enableZoom = true;

    var keyLight = new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 85%)'), 1.0);
    keyLight.position.set(-50, 0, 100);
    scene.add(keyLight);

    var objLoader = new OBJLoader();


    objLoader.load(link, function (object: any) {
      scene.add(object);
      object.position.y = 20;

    });

    var animate = function () {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };
    animate();
  }

  //open modal
  openModal(isRegenerated: boolean) {
    if (isRegenerated) {
      this.downloadFilesFromUrl();
    }
    let newFolder!: string;
    let dialogRef = this.dialog.open(FolderDialogComponent, {
      width: '40%',
      autoFocus: true

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.isSaved != false) {
        this.onSubmit(result.data.folderId, result.data.projectId, isRegenerated)
      }

    })
  }

  //download 2 object link
  async downloadFilesFromUrl() {
    let fileReader = new FileReader();
    fileReader.onload = () => {
    };
    let response = await fetch(this.reconstruction_face_link);
    let data = await response.blob();
    let metadata = {
      type: 'plain/text'
    };
    let fileName1 = this.reconstruction_face_link.split('file=')[1].split('.')[0] + '-regeneration.obj';
    let fileName2 = this.peace_link.split('file=')[1].split('.')[0] + '-filling-part.obj';
    this.fileRegeneration = new File([data], fileName1, metadata);
    this.fileFillingPart = new File([data], fileName2, metadata);


  }
  //upload file
  onSubmit(folderId: string, projectId: string, isRegenerated?: boolean) {
    this.isSavedLoading = true;
    var formData: any = new FormData();

    if (isRegenerated) {
      this.uploadForm.get('file')?.setValue([this.fileRegeneration, this.fileFillingPart]);

      // formData.append('uploads', this.uploadForm.get('file')?.value);
      formData.append('uploads', this.fileRegeneration);
      formData.append('uploads', this.fileFillingPart);

    } else formData.append('uploads', this.uploadForm.get('file')?.value);
    formData.append('folderId', folderId);
    formData.append('projectId', projectId);

    this.inventoryService.uploadFile(formData).subscribe(
      res => {
        console.log(res);
        this.fileUrl = res.data[0].url
        this.isSaved = true;
        this.isSavedLoading = false;
        this.snackbarService.open("Upload successfully", "Hide");
      },
      err => { },
      () => {

      }

    )
  }

  //regenerate
  regenerate() {
    this.spinnerService.showSpinner();
    this.isRegeneratedLoading = true;
    var formData: any = new FormData();
    formData.append('obj', this.uploadForm.get('file')?.value);
    let dialogRef = this.dialog.open(AiProcessDialogComponent, {
      width: '60%',
      data: { isRegenerate: true },
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.showSpinner = false
      }
    });
    this.regenerateService.regenerate(formData).subscribe(
      res => {
        this.isRegenerated = true;
        this.reconstruction_face_link = res.reconstructed_face
        this.peace_link = res.split_wound
        this.loadObjFileFromApi(this.reconstruction_face_link)
        this.isRegeneratedLoading = false;
        this.spinnerService.hideSpinner();
        this.snackbarService.open('Regenerate success', 'Hide')

      },
      err => {
        this.isRegeneratedLoading = false;
        this.spinnerService.hideSpinner();
        this.snackbarService.open('Regenerate fail', 'Hide');


      }
    )
  }
  switch(isFaced: boolean) {
    this.isFaced = isFaced
    if (isFaced) {
      this.loadObjFileFromApi(this.peace_link)
    } else this.loadObjFileFromApi(this.reconstruction_face_link)
  }
  back() {
    this.isRegenerated = false;
    this.isRegeneratedLoading = false;
    while (document.querySelectorAll('canvas').length != 0) {
      document.getElementsByTagName('canvas')[0].remove();
    }
  }

}
