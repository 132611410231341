import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/core/services/auth.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import { User } from 'src/app/core/models/user.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  hide = true;
  loginForm!: FormGroup;
  loading = false;
  submitted = false;
  error = false;

  isLoggedIn = false;
  isLoginFailed = false;
  isLoading = false;

  images = [
    '../../../assets/images/Cover1.png',
    '../../../assets/images/Cover2.png',
    '../../../assets/images/Cover3.png',
    '../../../assets/images/Cover4.png',
    '../../../assets/images/Cover5.png',
    // '../../../assets/images/Cover6.jpg',
    // '../../../assets/images/Cover7.jpg',
  ]
  randomImage = this.images[0];
  constructor(

    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,

    private authService: AuthService,
    private tokenStorage: JwtService,

    private snackbarService: SnackbarService

  ) {
    // this.message = this.getMessage();
    this.randomImage = this.images[Math.floor(Math.random() * this.images.length)]

  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, this.noWhitespaceValidator]],
      password: ['', [Validators.required, this.noWhitespaceValidator]]
    })

    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
    }
  }

  public noWhitespaceValidator(control: AbstractControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  validateUsername() {
    this.f['username'].invalid ? this.snackbarService.open('Invalid username pattern', 'Cancel') : null

  }

  validatePassword() {
    this.f['password'].invalid ? this.snackbarService.open('Password required more than 5 characters', 'Cancel') : null

  }
  onSubmit() {
    console.log('submit');
    this.isLoading = true;

    //snackbar here

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    let form = {
      username: this.f['username'].value.trim(),
      password: this.f['password'].value.trim()
    }
    console.log(form);
    console.log(this.f['username'].value);


    this.authService.login(form).subscribe(
      res => {
        if (res.code == 200) {

          this.tokenStorage.saveToken(res.token);
          let acc: User = this.tokenStorage.decodeToken(res.token)
          this.tokenStorage.saveUser(acc);

          this.isLoginFailed = false;
          this.isLoggedIn = true;

          this.isLoading = false;

          this.router.navigate(['/']);
          this.snackbarService.success("Login success", "Cancel")
        }
      },
      err => {
        console.log(err);
        this.loginForm.reset({ password: '', username: this.f['username'].value });
        this.isLoading = false;
        this.snackbarService.open('Invalid username or password', 'Cancel')
      },
      () => { }
    );
  }

  reloadPage(): void {
    window.location.reload();
  }

}
