import { Component, Input } from '@angular/core';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { JwtService } from '../../services/jwt.service';
import { Colors } from 'src/my-pallette';
import { Color } from 'three';
import { SnackbarService } from '../../services/snackbar.service';
import { Router } from '@angular/router';
import { SpinnerService } from '../../services/spinner.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  user!: User | null;
  loginBtnProps = { label: 'Login', color: Colors.SKY_MAIN, bgColor: Colors.SECONDARY_MAIN, width: '7rem' }
  constructor(private jwtService: JwtService, private snackbarService: SnackbarService, private route: Router,
    private spinnerService: SpinnerService, private authServie: AuthService,

  ) {
    this.user = this.jwtService.getUser();
    this.getUserById();

  }
  logout() {
    this.jwtService.signOut();
    this.user = null;
  }

  navigateToProfile() {
    this.route.navigate(['/', 'profile']);
  }
  getUserById() {
    const id: string = this.user?.id !== undefined ? this.user?.id : '';
    this.authServie.getUserById(id).subscribe(
      res => {
        if (res.data.length > 1) {
          this.user = null;
        } else
          this.user = res.data;
        this.spinnerService.hideSpinner();

      },
      err => {
        this.snackbarService.open('Something went wrong')
      },
      () => {

      }
    )
  }
}
