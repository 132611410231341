<div class="dialog-wrapper">
  <h1 mat-dialog-title>
    {{ title }}
  </h1>
  <div mat-dialog-content class="content-wrapper">{{ content }}</div>
  <div mat-dialog-actions class="footer-wrapper">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
      {{ btnContent }}
    </button>
  </div>
</div>
