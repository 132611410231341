import { Component } from '@angular/core';
import { InventoryService } from 'src/app/core/services/inventory.service';
import { AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { HistoryElement } from 'src/app/core/models/history.model';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/core/services/spinner.service';

export interface PeriodicElement {
  printerName: string;
  fileName: string;
  fileApplication: string;
  position: number;
  activityStatus: string;
  activityEstimatedTime: string;
  activityCreatedAt: string;
}

// const ELEMENT_DATA: PeriodicElement[] = []

@Component({
  selector: 'app-printing-history',
  templateUrl: './printing-history.component.html',
  styleUrls: ['./printing-history.component.scss']
})
export class PrintingHistoryComponent {
  displayedColumns: string[] = ['position', 'fileName', 'date', 'time', 'printer', 'application', 'status'];
  historyList: [] = [];
  temp: PeriodicElement[] = []

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  dataSource = new MatTableDataSource<PeriodicElement>([]);


  //loading 
  showSpinner = false;

  constructor(
    public router: Router,
    private inventoryService: InventoryService,
    private spinnerService: SpinnerService
  ) {
    this.spinnerService.spinner$.subscribe((data: boolean) => {
      setTimeout(() => {
        this.showSpinner = data ? data : false;
      });
    });
    this.spinnerService.showSpinner();

  }
  ngAfterViewInit() {
    this.inventoryService.getPrintingHistoryByUserId().subscribe(
      {
        next: (val) => {
          this.historyList = val.data;
          this.historyList && this.historyList.map((i: HistoryElement, index) => this.temp.push(Object.assign({}, {
            printerName: i.printerName,
            fileName: i.fileName + '.gcode',
            fileApplication: i.fileApplication.trim() == '' ? '' : i.fileApplication,
            position: index + 1,
            activityStatus: i.activityStatus,
            activityEstimatedTime: this.fixTime(i.activityEstimatedTime) + '',
            activityCreatedAt: new Date(i.activityCreatedAt).toLocaleDateString(),
          })
          ))

          this.dataSource = new MatTableDataSource(this.temp);
          this.dataSource.paginator = this.paginator;
          this.spinnerService.hideSpinner()

        },
        error: (err) => {
          this.spinnerService.hideSpinner()

        },
        complete: () => {
          this.spinnerService.hideSpinner()
        }
      }
    )
  }
  getSearchVal(val: string) {
    this.dataSource.filter = val.trim().toLowerCase();
  }
  fixTime(value: any) {
    if (value > 3600) {
      return (value / 3600).toFixed(2) + ' hours'
    } else {
      return (value / 3600).toFixed(2) + ' hour'
    };
  }
}
