import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../../models/user.model';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { JwtService } from '../../services/jwt.service';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-edit-profile-dialog',
  templateUrl: './edit-profile-dialog.component.html',
  styleUrls: ['./edit-profile-dialog.component.scss']
})
export class EditProfileDialogComponent {
  form!: FormGroup;
  changePassForm!: FormGroup;
  newData!: User;
  title!: string;
  bodyData!: any;
  isAccount = false

  languages = ['Vietnamese', 'English'];

  //for mat password input
  hide = true;
  hideSecond = true;

  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private tokenStorage: JwtService,
    private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<EditProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {

    this.newData = data.data;
    this.title = data.title;
    this.isAccount = data.isAccount
  }
  ngOnInit() {
    this.changePassForm = this.formBuilder.group({
      currentPassword: ['', [Validators.required, this.noWhitespaceValidator]],
      newPassword: ['', [Validators.required, this.noWhitespaceValidator]],
      confirmNewPassword: ['', [Validators.required, this.noWhitespaceValidator]],
    })
    this.form = this.formBuilder.group({
      username: [this.newData.username, [Validators.required, this.noWhitespaceValidator]],
      email: [this.newData.email, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), this.noWhitespaceValidator]],
      firstName: [this.newData.firstName, [Validators.required, this.noWhitespaceValidator]],
      lastName: [this.newData.lastName, [Validators.required, this.noWhitespaceValidator]],
      // sex: [this.newData.sex, Validators.required],
      // language: [this.newData.language, Validators.required],
    })


  }

  public noWhitespaceValidator(control: AbstractControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }
  get f() { return this.form.controls; }
  get fChange() { return this.changePassForm.controls; }

  validatePassword() {
    this.fChange['currentPassword'].invalid ? this.snackbarService.open('Password required more than 5 characters') : null
  }
  validateNewPassword() {
    this.fChange['newPassword'].invalid ?
      this.snackbarService.open('Password required more than 5 characters') : null;
    this.fChange['currentPassword'].value == this.fChange['newPassword'].value ? this.snackbarService.open('New passsword is the same as current password') : null
  }
  validateConfirmNewPassword() {
    if (this.fChange['confirmNewPassword'].value !== this.fChange['newPassword'].value) {
      this.snackbarService.open('Incorrect confirm password', 'Cancel')
    }
  }

  //validate edit profile pass
  validateFirstname() {
    this.f['firstname'].invalid ? this.snackbarService.open('Incorrect firstname pattern', 'Cancel') : null
  }
  validateLastname() {
    this.f['lastname'].invalid ? this.snackbarService.open('Incorrect lastname pattern', 'Cancel') : null
  }
  validateUsername() {
    this.f['username'].invalid ? this.snackbarService.open('Empty username pattern', 'Cancel') : null

  }
  validateEmail() {
    this.f['email'].invalid ? this.snackbarService.open('Incorrect email pattern', 'Cancel') : null
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit() {
    let form;
    if (this.isAccount) {
      console.log(this.fChange);
      console.log(this.fChange['currentPassword'].value);
      console.log(this.fChange['newPassword'].value);
      form = {
        oldPassword: this.fChange['currentPassword'].value,
        newPassword: this.fChange['newPassword'].value,
      }
    } else
      form = {
        username: this.f['username'].value,
        email: this.f['email'].value,
        firstName: this.f['firstName'].value,
        lastName: this.f['lastName'].value,
        // sex: this.f['sex'].value,
        // language: this.f['language'].value,

      }


    this.bodyData = form;

  }
}
