import { Component, Inject } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {
  durationSeconds = 2;
  defaultActionMessage = 'Hide';
  defaultMessage = 'Snackbar default message';


  constructor(
    private _snackBar: MatSnackBar, @Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }
  closeSnackbar() {
    this._snackBar.dismiss()
  }

  // error(message: string, action?: string) {
  //   return this._snackBar.open(message, action ?? 'Undo', {
  //     panelClass: ['snackbar-error', 'snackbar-container'],
  //     horizontalPosition: 'end',
  //     verticalPosition: 'top',
  //     duration: this.durationSeconds * 1000
  //   });
  // }

  // success(message: string, action: string) {
  //   return this._snackBar.open(message, action, {
  //     panelClass: ['snackbar-success'], horizontalPosition: 'end',
  //     verticalPosition: 'top',
  //     duration: this.durationSeconds * 1000

  //   });
  // }

  // info(message: string, action: string) {
  //   return this._snackBar.open(message, action, {
  //     panelClass: ['snackbar-info'], horizontalPosition: 'end',
  //     verticalPosition: 'top',
  //     duration: this.durationSeconds * 1000

  //   });
  // }

  // open(message: string, action?: string) {
  //   return this._snackBar.open(message != undefined ? message : this.defaultMessage, action != undefined ? action : this.defaultActionMessage, {
  //     panelClass: ['snackbar-info'],
  //     horizontalPosition: 'end',
  //     verticalPosition: 'top',
  //     duration: this.durationSeconds * 1000
  //   });
  // }
}
