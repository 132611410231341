<div class="dialog-wrapper">
  <h1 mat-dialog-title>Printer {{ printerInfo.name }} Information</h1>
  <div mat-dialog-content class="container">
    <div class="row">
      <div class="key">Name:</div>
      <div class="value">{{ printerInfo.name }}</div>
    </div>
    <div class="row">
      <div class="key">Activation Key:</div>
      <div class="value">{{ printerInfo.activationKey }}</div>
    </div>
    <div class="row">
      <div class="key">Consumables Diameter:</div>
      <div class="value">{{ printerInfo.consumablesDiameter }}</div>
    </div>
    <div class="row">
      <div class="key">Filament:</div>
      <div class="value">{{ printerInfo.filament }}</div>
    </div>
    <div class="row">
      <div class="key">Maximum Print Size:</div>
      <div class="value">{{ printerInfo.maximumPrintSize }}</div>
    </div>
    <div class="row">
      <div class="key">Maximum Printing Speed:</div>
      <div class="value">{{ printerInfo.maximumPrintingSpeed }}</div>
    </div>
    <div class="row">
      <div class="key">Model:</div>
      <div class="value">{{ printerInfo.model }}</div>
    </div>
    <div class="row">
      <div class="key">Nozzle Diameter:</div>
      <div class="value">{{ printerInfo.nozzleDiameter }}</div>
    </div>
    <div class="row">
      <div class="key">Slice Thickness:</div>
      <div class="value">{{ printerInfo.slice_thickness }}</div>
    </div>
  </div>
  <div class="btn-group">
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Close</button>
    </div>
    <div mat-dialog-actions *ngIf="isActivate">
      <button mat-button [mat-dialog-close]="true">Add printer</button>
    </div>
  </div>
</div>
