import { Project } from './../../core/models/project.model';
import { File } from './../../core/models/file.model';
import { Component, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { Folder } from 'src/app/core/models/folder.model';
import { InventoryService } from 'src/app/core/services/inventory.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { FileService } from 'src/app/core/services/file.service';


//for tree
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CreateDialogComponent } from 'src/app/core/layout/create-dialog/create-dialog.component';
//for paginator
import { ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Observable, ReplaySubject, delay, max, min, of } from 'rxjs';
import { EditDialogComponent } from 'src/app/core/layout/edit-dialog/edit-dialog.component';
import { JwtService } from 'src/app/core/services/jwt.service';
import { CdkDialogContainer } from '@angular/cdk/dialog';
import { DataSource } from '@angular/cdk/collections';
import { MatSort, Sort } from '@angular/material/sort';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { ConfirmDialogComponent } from 'src/app/core/layout/confirm-dialog/confirm-dialog.component';


interface ProjectNode {
  id: string;
  name: string;
  files?: File[];
  children?: Folder[];
  parentId?: any;
  projectId?: any;
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  n: ProjectNode
}

export interface PeriodicElement {
  id: string;
  position: number;
  name: string;
  img: string;
  size: string;
  application: string;
  url: string;
  extension?: string;
  createdAt: string;
}


@Component({
  selector: 'app-inventory-page',
  templateUrl: './inventory-page.component.html',
  styleUrls: ['./inventory-page.component.scss']
})


export class InventoryPageComponent {
  showSpinner = true;

  projects: Project[] = [];
  currentProject!: ProjectNode;//selected project
  currentProjectNode: ProjectNode[] = [];

  currentChildrens: File[] = [];
  currentChildrens$ !: BehaviorSubject<File[]>;
  currentFiles: File[] = [];

  currentNode!: ProjectNode;
  tempNode!: ProjectNode;
  isExpand: boolean = false;
  file!: any;
  fileName: string = "";

  //upload form
  uploadForm!: FormGroup;



  //tree goes here
  private _transformer = (node: ProjectNode, level: number) => {
    return {
      // expandable: !!node.children && node.children.length > 0,
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      n: node
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,


  );

  //TABLE
  columns = [
    {
      columnDef: 'image',
      header: '',
      cell: (element: PeriodicElement) => `${element.id}`,
    },
    {
      columnDef: 'position',
      header: 'No',
      cell: (element: PeriodicElement) => `${element.position}`,
    },
    {
      columnDef: 'name',
      header: 'Name',
      cell: (element: PeriodicElement) => `${element.name}${element.extension}`,
    },
    {
      columnDef: 'size',
      header: 'Size',
      cell: (element: PeriodicElement) => `${element.size}`,
    },
    {
      columnDef: 'date',
      header: 'Created Date',
      cell: (element: PeriodicElement) => `${this.formateDate(element.createdAt)}`,
    },
    {
      columnDef: 'application',
      header: 'Application',
      cell: (element: PeriodicElement) => `${element.application.trim() == '' ? '' : element.application}`,
    },
    {
      columnDef: 'action',
      header: '',
      cell: (element: PeriodicElement) => `${element}`,
    },
  ];

  //
  fileDataToDisplay: PeriodicElement[] = [];//table data to display
  subject$: BehaviorSubject<PeriodicElement[]> = new BehaviorSubject<PeriodicElement[]>([]);

  tableDataSource!: MatTableDataSource<PeriodicElement>;

  displayedColumns = this.columns.map(c => c.columnDef);
  //PAGINATOR
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  //FLATNODE
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  //behavior subject
  currentProjectNode$!: BehaviorSubject<ProjectNode[]>;
  constructor(
    private inventoryService: InventoryService,
    private element: ElementRef,
    private renderer: Renderer2,
    private _fb: FormBuilder,
    public loaderService: LoaderService,
    public router: Router,
    public fileService: FileService,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
    private jwt: JwtService,
    private snackBarService: SnackbarService,
    private spinnerService: SpinnerService
  ) {
    this.spinnerService.spinner$.subscribe((data: boolean) => {
      setTimeout(() => {
        this.showSpinner = data ? data : false;
      });
    });
    this.spinnerService.showSpinner();
  }

  //tree
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  menuTopLeftPosition = { x: 0, y: 0 }

  // reference to the MatMenuTrigger in the DOM
  @ViewChild('clickRightMenuTrigger') matMenuTrigger!: MatMenuTrigger;


  /**
   * Method called when the user click with the right button
   * @param event MouseEvent, it contains the coordinates
   * @param item Our data contained in the row of the table
   */

  ngOnInit() {
    this.getAllProjects();

    //init upload form
    this.uploadForm = this._fb.group({
      file: [''],
      projectId: [''],
      folderId: ['']
    });
  }
  //life cycle
  // ngOnChanges() {
  //   console.log('ngOnChanges');
  // }
  // ngDoCheck() {
  //   console.log('ngDoCheck');
  // }
  // ngAfterContentInit() {
  //   console.log('ngAfterContentInit');
  // }
  // ngAfterContentChecked() {
  //   console.log('ngAfterContentChecked');
  // }
  // ngAfterViewInit() {
  //   console.log('ngAfterViewInit');
  // }
  // ngAfterViewChecked() {
  //   console.log('ngAfterViewChecked');
  // }
  // ngOnDestroy() {
  //   console.log('ngOnDestroy');
  // }

  //TREE VIEW
  //get all root projects
  getAncestorsIndexes(node: ExampleFlatNode, index: number) {
    // console.log('_____________________________');
    // console.log('ANCESTORS');
    // console.log('_____________________________');

    // console.log('tree control', this.treeControl.dataNodes);
    let list = [index];
    let compareLevel = node.level;//4
    let currentFlatNode;
    let levelTrack = compareLevel - 1 < 0 ? 0 : compareLevel - 1;//3
    //while loop backward until meet level 0 node
    //index = 11 => list :11.7.6.3
    for (let idx = index - 1; idx >= 0; idx--) {
      console.log('____________________________');
      currentFlatNode = this.treeControl.dataNodes[idx < 0 ? 0 : idx]
      // console.log(`COMPARE ${currentFlatNode.level} vs ${levelTrack} ${currentFlatNode.level == levelTrack} && ${currentFlatNode.level} vs _${compareLevel - list.length} ${currentFlatNode.level == compareLevel - list.length}`);
      let condition = currentFlatNode.level == levelTrack && currentFlatNode.level == compareLevel - list.length;
      if (condition) {
        list.push(idx);
        levelTrack -= 1;
      }

    }
    return list;

  }
  changeExpandStatus() {
    this.isExpand = !this.isExpand;
  }
  expandNode(node?: ProjectNode) {

    if (this.tempNode != undefined) {
      // console.log('----------------');
      // console.log('EXPAND NODE');
      // console.log('treeControl', this.treeControl.dataNodes);
      // console.log('temp node ', this.tempNode);
      //set default flat node 
      let flatNode = this.treeControl.dataNodes[0];
      let index = 0;
      let level = 0;

      //find flatNode index in tree control with 
      this.treeControl.dataNodes.map((item: ExampleFlatNode, idx: number) => {
        if (item.n.id == node?.id) {
          index = idx;
          level = item.level;
          flatNode = item;
        }
      })


      //create a list index to expand to flat node
      //idea : from flatNode index , retrieve to the previouse index node has level-1
      //continue until access nearest node level 0
      //list sort increase index
      let list = this.getAncestorsIndexes(flatNode, index).reverse()

      list.forEach(ele => {
        this.treeControl.expand(this.treeControl.dataNodes[ele]);
      })
      // for (let idx = startIdx; idx < index; idx++) {
      //   console.log(`expand node idx ${idx} `, this.treeControl.dataNodes[idx]);
      //   this.treeControl.expand(this.treeControl.dataNodes[idx])
      // }

      this.getProjectContent(this.tempNode);

      this.tempNode == undefined;
      this.changeExpandStatus()
    }

  }
  getAllProjects(isCreateProject?: boolean): void {

    this.currentProjectNode = [];
    let currentClickProject;
    this.inventoryService.getAllProjects().subscribe(data => {
      this.projects = data.data.filter((i: any) => i.userId == this.jwt.getUser().id);
      // if (curProject == undefined) {
      //   this.currentProject = {
      //     id: this.projects[0].id,
      //     name: this.projects[0].name,
      //     files: this.projects[0].files,
      //     children: this.projects[0].folder
      //   }
      // } else {
      //   currentClickProject = this.projects.filter(i => i.id == curProject!.id)[0];
      //   this.currentProject = {
      //     id: currentClickProject.id,
      //     name: currentClickProject.name,
      //     files: currentClickProject.files,
      //     children: currentClickProject.folder
      //   }
      // }

      this.projects.map(p => {
        var folderArr!: Folder[];
        var fileArr!: File[];
        this.inventoryService.getProjectById(p.id).subscribe(
          {
            next: (v: any) => {
              folderArr = v.data.folder;
              fileArr = v.data.files;
              this.currentProjectNode.push(Object.assign({}, { id: p.id, name: p.name, files: fileArr, children: folderArr }));

              this.dataSource.data = this.currentProjectNode
              console.log('CURRENT PROJECT NODE', this.currentProjectNode);

            },
            error: (e) => { },
            complete: () => {

              if (this.currentProjectNode.length == this.projects.length) {
                console.log('same length');

                //get default project 
                //put temp Node here for expand project
                let defaultProject = isCreateProject ? this.currentProjectNode[this.projects.length - 1] : this.currentProjectNode[0];

                // if (curProject != undefined) {
                //   defaultProject = this.currentProjectNode.filter(i => i.id == curProject.id)[0];
                // }

                if (this.isExpand) {
                  console.log('-------');
                  console.log('TRUE EXPAND');
                  console.log('NODE: ', this.currentNode);
                  let id = this.currentNode.id;
                  //get flat node of current node to init project
                  let flatNode = this.treeControl.dataNodes[0];
                  this.treeControl.dataNodes.map((item: ExampleFlatNode, idx: number) => {

                    if (item.n.id == id) {
                      flatNode = item;
                    }
                  })
                  console.log('FLAT NODE :', flatNode);
                  //get  new data of table here
                  this.getProjectContent({
                    id: flatNode.n.id,
                    name: flatNode.n.name,
                    files: flatNode.n.files,
                    children: flatNode.n.children
                  }, undefined, this.isExpand)
                } else {
                  this.getProjectContent({
                    id: defaultProject.id,
                    name: defaultProject.name,
                    files: defaultProject.files,
                    children: defaultProject.children
                  }, undefined, this.isExpand)
                }

              }
            },
          },
        );
      }
      );
    },
      err => { },
      () => {
        this.spinnerService.hideSpinner();
      });
  }

  //get single project folders & files
  getProjectContent(node: ProjectNode, id?: string, isExpanded?: boolean): void {
    //set current flat node for expand after CRUD file
    this.fileDataToDisplay = [];
    // const ele = document.getElementsByClassName('active-node')[0] as HTMLElement
    // ele.style.background = "#FFFFFF"
    document.getElementsByClassName('active-node')[0]?.classList.remove('active-node')
    document.getElementById(node.id)?.classList.add('active-node')

    this.currentChildrens = node.files as File[];
    node.files && node.files.sort((a, b) => { return <any>new Date(b.createdAt) - <any>new Date(a.createdAt) }).map((i, index) => this.fileDataToDisplay.push(Object.assign({},
      {
        id: i.id,
        position: index + 1,
        name: i.name,
        img: "alo",
        size: this.formatFileSize(i.size),
        application: i.application,
        url: i.url,
        extension: i.extension,
        createdAt: i.createdAt
      }

    ))
    )

    this.tableDataSource = new MatTableDataSource(this.fileDataToDisplay)
    this.tableDataSource.paginator = this.paginator;
    this.tableDataSource.sortData = this.sortData();
    this.tableDataSource.sort = this.sort;

    this.currentNode = node;
    this.currentProject = node

    if (isExpanded) {

      // console.log('--NODE', node);
      // console.log('fileDataToDisplay', this.fileDataToDisplay);
      this.tempNode = node;
      //update tempNode after load all new data
      this.expandNode(this.tempNode);
    }
  }

  formatFileSize(size: number) {
    var data = '';
    if (size < 1024) {
      data = size + ' B';

    } else if (size > 1024 && size < 1024 * 1024) {
      data = (size / (1024)).toFixed(2) + ' KB';

    } else {
      data = (size / (1024 * 1024)).toFixed(2) + ' MB';
    }
    return data;
  }
  getSearchVal(val: string) {
    this.tableDataSource.filter = val.trim().toLowerCase()
  }

  //SORT
  clearSort() {
    this.sort.sort({ id: '', start: 'asc', disableClear: false });
  }

  convertSizeString(sizeString: String) {
    let actualSize;

    var num = sizeString.split(' ')[0];
    var unit = sizeString.split(' ')[1];

    if (unit == 'B') {
      actualSize = num;
    } else if (unit == 'KB') {
      actualSize = Number(num) * 1024;
    } else {
      actualSize = Number(num) * 1024 * 1024;

    }
    return actualSize;
  }
  sortData() {
    let sortFunction =
      (items: PeriodicElement[], sort: MatSort): PeriodicElement[] => {
        if (!sort.active || sort.direction === '') {
          return items;
        }
        return items.sort((a: PeriodicElement, b: PeriodicElement) => {
          let comparatorResult = 0;
          switch (sort.active) {
            case 'position':
              comparatorResult = a.position < b.position ? -1 : 1;
              break;
            case 'name':
              comparatorResult = a.name.toLowerCase().localeCompare(b.name);
              break;
            case 'application':
              comparatorResult = a.application.toLowerCase().localeCompare(b.application);
              break;
            case 'date':
              comparatorResult = a.createdAt.localeCompare(b.createdAt);
              break;
            case 'size':
              comparatorResult = this.convertSizeString(a.size) < this.convertSizeString(b.size) ? -1 : 1;
              break;
            default:
              comparatorResult = a.name.localeCompare(b.name);
              break;
          }
          return comparatorResult * (sort.direction == 'asc' ? 1 : -1);
        });
      };
    return sortFunction;
  }

  //CRUD PROJECT AND FOLDER
  onTriggerContextMenu(event: MouseEvent, item?: any) {

    console.log('mat menu trigger', this.matMenuTrigger);
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX;
    this.menuTopLeftPosition.y = event.clientY;

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item: item }

    // we open the menu

    this.matMenuTrigger.menuClosed;
    // this.matMenuTrigger.openMenu()

  }


  editProjectTree(item: any, parent?: any) {


    let isProject = item.parentId == undefined && item.projectId == null;
    let result = "";
    if (isProject) {
      //edit Project name
      this.openEditDialog(item, `Edit project ${item.name}`, true);

    } else {
      //edit Folder name
      this.openEditDialog(item, `Edit folder ${item.name}`, false, parent)

    }
  }
  //open edit dialog for project and folder name
  openEditDialog(item: any, title?: string, isProject?: boolean, parent?: any): any {
    let newFolder = item.name;
    let dialogRef = this.dialog.open(EditDialogComponent, {
      width: 'fit-content%',
      data: { title: title ?? 'Edit dialog', data: newFolder },
      autoFocus: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.trim() == '') { this.snackBarService.open('Empty new name'); }
        else if (result.trim() == item.name) { this.snackBarService.open('Nothing change'); }
        else {
          if (isProject) this.editName(item, result, true)
          else this.editName(item, result, false, parent)
        }
      }
    })

  }

  editName(item: any, newName: string, isProject: boolean, parent?: any) {
    let isDuplicate = false;
    if (isProject) {
      this.currentProjectNode.length > 0 && this.currentProjectNode.map(node => {
        if (node.name.toUpperCase() == newName.trim().toUpperCase()) {
          isDuplicate = true;
        }
      });
      if (isDuplicate) {
        this.snackBarService.open('Duplicate project name')
      } else
        this.inventoryService.editProject(item.id, newName).subscribe(
          res => {
            if (res.code == 200) {
              this.isExpand = true
              this.getAllProjects();
              this.snackBarService.open('Edit project successfully');
            }
          },
          err => { }
        )
    } else {
      if (isDuplicate) {
        this.snackBarService.open('Duplicate folder name');
      } else
        this.inventoryService.editFolder(item.id, newName, item.parentId, item.projectId).subscribe(
          res => {
            if (res.code == 200) {
              this.isExpand = true
              this.getAllProjects();
              this.snackBarService.open('Edit folder successfully');
            }
          },
          err => { }
        );
    }
  }
  deleteProjectTree(item: any) {
    //if project parentId==undefinded
    let isProject = item.parentId == undefined && item.projectId == null;
    let result = "";
    if (isProject) {
      //edit Project name
      this.openDeleteDialog(item, `delete project ${item.name}`, true);

    } else {
      //edit Folder name
      this.openDeleteDialog(item, `delete folder ${item.name}`, false)

    }
  }
  openDeleteDialog(item: any, title?: string, isProject?: boolean): any {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: 'fit-content%',
      data: {
        title: 'Confirm delete dialog',
        btnContent: 'Delete',
        content: isProject ?
          `Are you sure to delete project ${item.name}?`
          : `Are you sure to delete folder ${item.name}?`
      },
      autoFocus: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteTree(item, isProject as boolean)
      }
    })
  }
  deleteTree(item: any, isProject: boolean) {
    if (isProject) {
      this.inventoryService.deleteProject(item.id).subscribe(
        res => {
          if (res.code == 200) {
            this.isExpand = true
            this.getAllProjects();
            this.snackBarService.open('Delete project successfully');
          }
        },
        err => this.snackBarService.open('Delete project failed')
      )
    } else {
      this.inventoryService.deleteFolder(item.id).subscribe(
        res => {
          if (res.code == 200) {
            this.isExpand = true
            this.getAllProjects();
            this.snackBarService.open('Delete folder successfully');
          }
        },
        err => this.snackBarService.open('Delete folder failed')
      );
    }
  }


  //FILE UPLOAD
  handleFileInput(e: any) {
    const file: File = e.target.files[0];
    let extension = file.name.split('.').pop()
    if (extension != 'obj') {
      this.snackBarService.open('Accept file .obj extenstion only');
    } else {
      this.uploadForm.get('file')?.setValue(file)

    }

  }
  onSubmit(current: any) {
    var formData: any = new FormData();
    let isDuplicate = false;
    let uploadFileName = this.uploadForm.get('file')?.value.name as String;
    isDuplicate = this.currentChildrens.filter((i) => i.name == uploadFileName.split('.')[0]
    ).length > 0

    if (isDuplicate) {
      this.snackBarService.open('Duplicate file name');
    } else {
      formData.append('uploads', this.uploadForm.get('file')?.value);

      if (Object.keys(this.currentNode).length > 4) {
        formData.append('folderId', this.currentNode.id)
      } else {
        formData.append('projectId', this.currentProject.id);
      }

      let res: any
      this.inventoryService.uploadFile(formData).subscribe(
        data => {
          res = data;
          if (res.code == 200) {
            this.isExpand = true;
            this.getAllProjects()
            this.snackBarService.open('Upload file successfully')
          }
        },
        err => {

        })
    }
  }


  getFilePreview(file: File) {
    let navigationExtras: NavigationExtras
    if (Object.keys(this.currentNode).length > 4) {
      navigationExtras = { state: { fileId: file.id, fileName: file.name, projectId: null, folderId: this.currentNode.id, url: file.url, application: file.application } };
    } else {
      navigationExtras = { state: { fileId: file.id, fileName: file.name, projectId: this.currentNode.id, folderId: null, url: file.url, application: file.application } };
    }
    this.router.navigate(['/inventory/preview'], navigationExtras);
  }


  //FOLDERS CRUD
  openCreateFolderDialog(isProject?: boolean): void {
    let newFolder!: string;
    let dialogRef = this.dialog.open(CreateDialogComponent, {
      width: 'fit-content%',
      data: { title: isProject ? 'New project name dialog' : 'New folder name dialog', btnContent: 'Create', newFolder: '' },
      autoFocus: true

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (isProject) {
          this.createProject(result)
        } else this.createFolder(result)
      }

    })
  }

  createFolder(result: any) {
    if (result.trim() == '' as string) {
      this.snackbarService.open('Empty folder name', 'Cancel')
    } else {
      let isDuplicate = false;
      if (this.currentProject?.children != undefined) {
        isDuplicate = this.currentProject?.children?.filter(i => i.name == result).length > 0;
      }
      if (isDuplicate) {
        this.snackBarService.open('Duplicate folder name');
      } else {
        interface ReqBody {
          name: string,
          createdAt: string,
          parentId: any,
          projectId: any
        };
        let body: ReqBody = {
          name: result,
          createdAt: new Date().toISOString(),
          parentId: null,
          projectId: null
        };
        let firstCond = this.currentNode.projectId == undefined;
        let secondCond = this.currentNode.parentId == null || this.currentNode.parentId == undefined
        if (firstCond && secondCond) {
          body.projectId = this.currentNode.id
        } else {
          body.parentId = this.currentNode.id
        }

        this.inventoryService.createFolder(body).subscribe(
          res => {
            this.isExpand = true
            this.getAllProjects()
            if (res.code == 200) {
              this.snackbarService.open('Create folder successfully', 'Cancel');
            }
          },
          err => { },
          () => {
          }
        )
      }
    }

  }
  createProject(result: any) {
    if (result != undefined) {
      if (result.trim() == '') {
        this.snackBarService.open('Empty project name', 'Cancel');
      } else {
        let isDuplicate = false;
        this.currentProjectNode.length > 0 && this.currentProjectNode.map(node => {
          if (node.name.toUpperCase() == result.trim().toUpperCase()) {
            isDuplicate = true;
          }
        });

        if (isDuplicate) {
          this.snackBarService.open('Duplicate project name', 'Cancel')
        } else {
          interface ReqBody {
            name: string,
            createdAt: string,
            userId: string
          };
          let body: ReqBody = {
            name: result,
            createdAt: new Date().toISOString(),
            userId: this.jwt.getUser().id
          };


          this.inventoryService.createProject(body).subscribe(
            res => {
              if (res.code == 200) {
                this.isExpand = true
                this.getAllProjects(true);
                this.snackBarService.open('Create project successfully', 'Cancel');
              }
            },
            err => { },
            () => {
            }
          )
        }
      }
    };


  }

  //FILES CRUD
  openEditFileDialog(ele: any): void {
    let newFolder = ele.name;
    let dialogRef = this.dialog.open(EditDialogComponent, {
      width: 'fit-content%',
      data: { title: 'Edit file name', data: newFolder },
      autoFocus: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.trim() != '') {
          this.updateFile(ele.id, result)
        }
      }
    })
  }

  updateFile(id: string, result: string) {
    let isProject = this.currentNode.parentId == undefined && this.currentNode.projectId == undefined;

    interface ReqBody {
      name: string,
      createdAt: string,
      projectId?: any,
      folderId?: string | null
    };

    let body!: ReqBody;
    if (isProject) {
      body = {
        name: result,
        createdAt: new Date().toISOString(),
        projectId: this.currentNode.id,
        folderId: null
      }
    } else {
      body = {
        name: result,
        createdAt: new Date().toISOString(),
        projectId: null,
        folderId: this.currentNode.id
      }
    }


    this.inventoryService.updateFile(id, body).subscribe(
      res => {
        this.snackbarService.open("Rename successfully", "Hide");

        //expand tree node when managa file
        this.isExpand = true;
        this.getAllProjects();
      },
      err => {
        this.snackbarService.open("Something went wrong", "Hide");
      },
      () => {
      }

    )
  }

  deleteFile(ele: any) {
    this.inventoryService.deleteFile(ele.id).subscribe(
      res => {
        if (res.code == 200) {
          this.snackbarService.open("Delete successfully", "Hide");
          this.isExpand = true
          this.getAllProjects()
        }
      },
      err => this.snackbarService.open('Delete failed', 'Hide'),
      () => {


      }
    )
  }

  downloadFile(ele: any) {
    this.inventoryService.downloadFile(ele.url)
  }

  formateDate(date: string) {
    let newDate = new Date(date);
    const formatLocalDateString = newDate.toLocaleDateString('en-GB') + ' , ' + newDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false });
    return formatLocalDateString;
  }
}


