<div class="container">
  <div class="breadcrum-wrapper">
    <div class="cube-img-wrapper">
      <img *ngIf="!isBack" src="../../../assets/images/Cube.png" alt="" />
      <div *ngIf="isBack" (click)="onClickHandler()" class="icon-wrapper">
        <mat-icon>arrow_back</mat-icon>
      </div>
    </div>
    <div class="breadcrum-content">{{ content }}</div>
  </div>
</div>
