import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/core/services/auth.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import { User } from 'src/app/core/models/user.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  loginForm!: FormGroup;
  loading = false;
  submitted = false;
  error = false;

  isLoggedIn = false;
  isLoginFailed = false;
  isLoading = false;

  images = [
    '../../../assets/images/Cover1.png',
    '../../../assets/images/Cover2.png',
    '../../../assets/images/Cover3.png',
    '../../../assets/images/Cover4.png',
    '../../../assets/images/Cover5.png',
    // '../../../assets/images/Cover6.jpg',
    // '../../../assets/images/Cover7.jpg',
  ]
  randomImage = this.images[0];
  constructor(

    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,

    private authService: AuthService,
    private tokenStorage: JwtService,

    private snackbarService: SnackbarService

  ) {
    // this.message = this.getMessage();
    this.randomImage = this.images[Math.floor(Math.random() * this.images.length)]

  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      // password: ['', Validators.required]
    })

    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  validateEmail() {
    this.f['email'].invalid ? this.snackbarService.open('Invalid email pattern', 'Cancel') : null

  }

  validatePassword() {
    this.f['password'].invalid ? this.snackbarService.open('Password required more than 5 characters', 'Cancel') : null

  }
  onSubmit() {
    this.isLoading = true;

    //snackbar here

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    let form = {
      email: this.f['email'].value,
      // password: this.f['password'].value
    }

    console.log(this.f['email'].value);


    this.authService.forgotPassword(this.f['email'].value).subscribe(
      res => {
        if (res.code == 200) {
          console.log(res);

          // this.tokenStorage.saveToken(res.token);
          // let acc: User = this.tokenStorage.decodeToken(res.token)
          // this.tokenStorage.saveUser(acc);

          // this.isLoginFailed = false;
          // this.isLoggedIn = true;

          this.isLoading = false;

          // this.router.navigate(['/']);
          this.snackbarService.success("Sent code success", "Cancel")
        }
      },
      err => {
        console.log(err);
        this.loginForm.reset({ password: '', email: this.f['email'].value });
        this.isLoading = false;
        this.snackbarService.open('Invalid email or password', 'Cancel')
      },
      () => { }
    );
  }

  reloadPage(): void {
    window.location.reload();
  }
}
