<div class="dialog-wrapper">
  <h1 mat-dialog-title class="text bold">Folder explorer</h1>
  <div mat-dialog-content class="project-list">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodePadding
        id="{{ node.n.id }}"
        (click)="getProjectContent(node.n)"
      >
        <!-- use a disabled button to provide padding for tree leaf -->
        <button mat-icon-button disabled></button>
        <img
          *ngIf="node.level != 0"
          class="folder-icon"
          src="../../../assets/images/folder-icon.png"
          alt=""
        />
        <img
          *ngIf="node.level == 0"
          class="folder-icon"
          src="../../../assets/images/project-icon.png"
          alt=""
          width="30px"
        />
        {{ node.name.trim() == "" ? "[Blank]" : node.name }}
      </mat-tree-node>

      <!-- This is the tree node template for expandable nodes -->
      <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePadding
        id="{{ node.n.id }}"
        (click)="getProjectContent(node.n)"
      >
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{
              treeControl.isExpanded(node.n) ? "expand_more" : "chevron_right"
            }}
          </mat-icon>
        </button>
        <img
          *ngIf="node.level != 0"
          class="folder-icon"
          src="../../../assets/images/folder-icon.png"
          alt=""
        />
        <img
          *ngIf="node.level == 0"
          class="folder-icon"
          src="../../../assets/images/project-icon.png"
          alt=""
          width="30px"
        />
        {{ node.name.trim() == "" ? "[Blank]" : node.name }}
      </mat-tree-node>
    </mat-tree>
  </div>
  <div mat-dialog-actions>
    <button class="text" mat-button (click)="onNoClick()">Cancel</button>
    <button
      class="text"
      mat-button
      [mat-dialog-close]="{
        data: { folderId: folderId, projectId: projectId },
        isSaved: true
      }"
      cdkFocusInitial
    >
      Save
    </button>
  </div>
</div>
