import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { JwtService } from '../services/jwt.service';


@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(private loaderService: LoaderService, private jwtService: JwtService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loaderService.isLoading.next(true);

        let request;
        if (this.jwtService.getToken() == undefined) {
            request = req.clone(
                {
                    headers: req.headers.set("Access-Control-Allow-Origin", '*')
                }
            );
        } else {
            request = req.clone(
                {
                    headers: req.headers.set("Access-Control-Allow-Origin", '*')
                        .set('Authorization', `Bearer ${this.jwtService.getToken()}`)
                }
            );
        }


        return next.handle(request).pipe(
            finalize(
                () => {
                    this.loaderService.isLoading.next(false)
                }
            )
        );
    }
}