<div class="dialog-wrapper">
  <h1 mat-dialog-title>New printer MAC</h1>
  <div mat-dialog-content>
    <input
      type="text"
      (change)="setValue($event)"
      placeholder="Enter MAC"
      name="newFolder"
      id="input"
    />
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="key" cdkFocusInitial>Check</button>
  </div>
</div>
