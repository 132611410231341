import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/core/models/user.model';
import { JwtService } from 'src/app/core/services/jwt.service';

@Component({
  selector: 'app-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.scss']
})
export class UtilitiesComponent {
  user!: User | null;

  constructor(public router: Router, private jwtService: JwtService) {
    this.user = this.jwtService.getUser()

  }
  ngDoCheck() {
    this.user = this.jwtService.getUser()
  }
}
