<div class="dialog-wrapper">
  <h1 mat-dialog-title>
    {{ title }}
  </h1>
  <div mat-dialog-content class="content-wrapper">
    <input
      type="text"
      [(ngModel)]="newFolder"
      placeholder="Enter name"
      name="newFolder"
      id=""
    />
  </div>
  <div mat-dialog-actions class="footer-wrapper">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="newFolder" cdkFocusInitial>
      {{ btnContent }}
    </button>
  </div>
</div>
