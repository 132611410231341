import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent {
  form!: FormGroup;
  oldData!: string;
  newData!: string;
  title!: string;
  isTouched = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {

    this.newData = data.data;
    this.oldData = data.data;
    this.title = data.title

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
