<div class="home-page-wrapper">
  <app-header></app-header>
  <app-breadcrum
    [isBack]="true"
    (onClick)="this.router.navigate(['/'])"
    [content]="'Endless dreams, ageless imagination'"
  ></app-breadcrum>
  <div class="body-wrapper">
    <div class="services-container">Under Construction</div>
    <app-footer></app-footer>
  </div>
</div>
