import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Printer } from 'src/app/core/models/printer.model';
import { LoaderService } from 'src/app/core/services/loader.service';
import { PrinterService } from 'src/app/core/services/printer.service';
import { PrintingService } from 'src/app/core/services/printing.service';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { AiProcessDialogComponent } from 'src/app/core/layout/ai-process-dialog/ai-process-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/core/services/spinner.service';

@Component({
  selector: 'app-inventory-printing-page',
  templateUrl: './inventory-printing-page.component.html',
  styleUrls: ['./inventory-printing-page.component.scss']
})
export class InventoryPrintingPageComponent {
  private fileId!: string;
  private fileName!: string;
  private application!: any;
  private folderId!: any;
  private projectId!: string;
  f!: File;
  url!: any;
  responseData!: Blob;

  objLink!: string;
  intendTime: string = "0 sec";
  estimatedTime: number = 0;
  printingForm: any;

  printersList: Printer[] = [];
  selectedVal: string = "";
  selectedQua: string = "speed";
  selectedMat: string = "PLA";

  progress$!: Observable<any>;

  isIntended = false;

  gcodeUrl!: string;


  isIntendLoading = false;
  isPrintLoading = false;
  isFileDownloading = false;


  //loading
  showSpinner = true;
  constructor(private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private printingService: PrintingService,
    private printerService: PrinterService,
    public loaderService: LoaderService,
    private snackbarService: SnackbarService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog) {
    this.spinnerService.spinner$.subscribe((data: boolean) => {
      setTimeout(() => {
        this.showSpinner = data ? data : false;
      });
    });
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as { fileId: string, fileName: string, projectId: string, folderId: string, url: string, application: string };
    // this.objLink = state.url
    this.objLink = state.url;
    this.fileId = state.fileId;
    this.fileName = state.fileName;
    this.application = state.application;
    this.folderId = state.folderId;
    this.projectId = state.projectId;
    this.printingForm = new FormGroup({
      printer: new FormControl(),
      quality: new FormControl(),
      material: new FormControl(),
    })

  }
  ngOnInit() {
    // this.loadObjFileFromAPI();
  }
  ngAfterViewInit() {
    this.loadObjFileFromAPI();
  }
  ngAfterViewChecked() {
    // if (this.isIntended == false) {
    //   this.loadObjFileFromAPI()

    // }
  }
  navigateToInventory() {
    this.router.navigate(['/inventory']);
  }
  ngAfterContentInit() {
    this.progress$ = this.printerService.progress$

    this.printerService.getAllPrinters().subscribe(data => {
      console.log(data.data);
      this.printersList = data.data;
      this.selectedVal = data.data[1].activationKey
    })
  }

  //get file content by call api
  loadObjFileFromAPI() {
    // let link: string = environment.api + this.fileName + '-' + this.fileId + '.obj';

    // this.objLink = link

    var scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);
    var camera = new THREE.PerspectiveCamera(50, window.innerWidth / 1.35 / window.innerHeight / 1.35, 1, 1000);

    // geometry.translate(0, 0, 0);

    camera.position.z = 420; 
    // camera.position.x = 0;
    // camera.position.y = 0;
    var renderer = new THREE.WebGLRenderer();
    renderer.setSize(1500, 1450); //old 500, 450


    //threejs frame
    const container = document.getElementsByClassName('myObject')[0];
    container?.appendChild(renderer.domElement);

    var can = document.querySelectorAll('canvas')[0];
    can!.style.position = 'relative';

    var controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.enableZoom = true;

    var keyLight = new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 82%)'), 1.0);
    // keyLight.position.set(-50, 0, 100);
    var keyLight = new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 75%)'), 1.0);
    var keyLight2 = new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 55%)'), 1.0);
    var keyLight3 = new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 55%)'), 1.0);
    var keyLight4 = new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 55%)'), 1.0);
    var keyLight5 = new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 55%)'), 1.0);
    var keyLight6 = new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 75%)'), 1.0);
    // keyLight.position.set(-50, 0, 100);
    keyLight.position.set(1, 0, 0);
    scene.add(keyLight);
    keyLight2.position.set(0, 1, 0);
    scene.add(keyLight2);
    keyLight3.position.set(0, 0, 1);
    scene.add(keyLight3);
    keyLight4.position.set(-1, 0, 0);
    scene.add(keyLight4);
    keyLight5.position.set(0, -1, 0);
    scene.add(keyLight5);
    keyLight6.position.set(0, 0, -1);
    scene.add(keyLight6);
    this.spinnerService.showSpinner();


    const loadingManager = new THREE.LoadingManager(() => {
    });
    loadingManager.onStart = () => {
      // console.log('on start');
      // this.spinnerService.showSpinner()
    }
    // loadingManager.onProgress = () => {
    //   console.log('progress');
    //   // this.spinnerService.showSpinner()
    // }
    loadingManager.onLoad = () => {
      // console.log('on load');
      // this.spinnerService.hideSpinner();
    }
    loadingManager.onError = () => {
      // console.log('err');
      // this.spinnerService.hideSpinner();
    }
    var objLoader = new OBJLoader(loadingManager);
    objLoader.load(
      this.objLink,

      function (object: any) {
        camera.lookAt(object.position)
        scene.add(object);
      });



    var animate = function () {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };
    animate();
    this.spinnerService.hideSpinner();
  }
  switch() {
    this.isIntended = !this.isIntended
    window.location.reload();
  }

  //get data for custom printer: all printers, time estimated
  onSubmit() {
    this.print()
  }
  print() {
    this.isPrintLoading = true;

    let body = {
      printerActivationKey: this.selectedVal,
      estimatedTime: Number(this.estimatedTime),
      fileId: this.fileId,
      status: 'PENDING',
      application: this.application
    }
    this.printingService.print(body).subscribe(
      res => {
        console.log(body);
        if (res.code == 200) {
          this.router.navigate(['warehouse'], { state: { time: this.intendTime, printerId: this.printingForm.value.printer } })
        }
      },
      err => {
        this.isPrintLoading = false;
        this.snackbarService.open('The printer is using', 'Hide')
      },
      () => {
      }
    )
  }


  intend() {
    this.isIntendLoading = true

    //get estimated time, gcode link
    let body = new FormData();
    this.selectedVal = this.printingForm.value.printer;
    body.append('printerID', this.printingForm.value.printer);
    body.append('density', "25%");
    body.append('quality', this.printingForm.value.quality);
    body.append('model', this.objLink);
    body.append('material', this.printingForm.value.material);
    if (this.folderId == null) {
      body.append('projectId', this.projectId);
    } else body.append('folderId', this.folderId);

    body.append('fileName', this.fileName + '.obj');

    //open ai model processing
    let dialogRef = this.dialog.open(AiProcessDialogComponent, {
      width: '60%',
      data: { isRegenerate: false },
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
      }
    });

    this.printingService.getIntentTime(body).subscribe(
      res => {
        this.gcodeUrl = res.data;
        this.fileId = res.id;
        this.estimatedTime = res.TIME
        if (res.TIME > 3600) {
          this.intendTime = (res.TIME / 3600).toFixed(2) + ' hours'
        } else {
          this.intendTime = (res.TIME / 3600).toFixed(2) + ' hour'
        };
        this.isIntended = true;

        this.isIntendLoading = false;

      },
      err => {
        this.isIntendLoading = false;
        this.snackbarService.open('Something went wrong with slicing', 'Hide');
        this.spinnerService.hideSpinner();

      },
      () => {
        this.spinnerService.hideSpinner();

      }
    )

  }

  download() {
    this.isFileDownloading = true;
    this.isIntended = true;
    console.log(this.gcodeUrl);
    window.open(this.gcodeUrl);
    this.isFileDownloading = false;
    // this.isIntended = true;
  }

}



