<footer class="footer">
  <div class="footer-row-wrapper">
    <div class="footer_address">
      <div class="footer__logo">HORIZON TEK</div>
      <div class="phone"><mat-icon>phone</mat-icon>(+84) 814 808 089</div>
      <div>
        <a class="footer__btn" href="mailto:example@gmail.com"
          ><mat-icon>email</mat-icon> cirtech@hutech.edu.vn</a
        >
      </div>
    </div>
    <div class="nav-wrapper">
      <ul class="footer__nav">
        <li class="nav__item">
          <p class="nav__title">Quick Links</p>

          <ul class="nav__ul">
            <li>
              <a href="#">Product</a>
            </li>

            <li>
              <a href="#">Information</a>
            </li>

            <li>
              <a href="#">About us</a>
            </li>
          </ul>
        </li>
        <li class="nav__item">
          <p class="nav__title">Information</p>

          <ul class="nav__ul">
            <li>
              <a href="#">FnA</a>
            </li>

            <li>
              <a href="#">Blog</a>
            </li>

            <li>
              <a href="#">Support</a>
            </li>
          </ul>
        </li>

        <li class="nav__item">
          <p class="nav__title">Subscribe</p>
          <ul class="nav__ul">
            <li>
              <a href="#">Privacy Policy</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>

  <hr />

  <div class="legal">
    <div class="item btn-groups">
      <a class="btn link" href="https://sites.google.com/view/horizon-tek-info">
        <mat-icon> public </mat-icon>
      </a>
      <a class="btn link" href="https://www.facebook.com/cirtech.Institute"
        ><mat-icon> facebook </mat-icon></a
      >
    </div>
    <div class="item">
      <div class="align-center">A product of CIRTech Team</div>
    </div>
    <div class="item">
      <div class="legal__links">
        <span>© 2023 CIRTech. All rights reserved</span>
      </div>
    </div>
  </div>
</footer>
