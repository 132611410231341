<div class="home-page-wrapper">
  <app-header></app-header>
  <app-breadcrum
    [isBack]="true"
    (onClick)="this.router.navigate(['/'])"
    [content]="'Printing Profiles'"
  ></app-breadcrum>
  <div class="body-wrapper">
    <div class="services-container">
      <div class="service-item">
        <div class="icons-group">
          <div class="item">
            <img src="../../../assets/images/logo/printer-setting.png" alt="" />
            <div class="name">Printer Settings</div>
          </div>
          <div class="item">
            <img src="../../../assets/images/logo/materials.png" alt="" />
            <div class="name">Materials</div>
          </div>
          <div class="item">
            <img src="../../../assets/images/logo/slicer-settings.png" alt="" />
            <div class="name">Slicer Settings</div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
