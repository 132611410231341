import { BehaviorSubject, Observable, Subject, Subscription, concatMap, delay, from, interval, map, of, takeUntil } from 'rxjs';
import { Component, Inject } from '@angular/core';
import { Printer } from 'src/app/core/models/printer.model';
import { PrinterService } from 'src/app/core/services/printer.service';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { InventoryService } from 'src/app/core/services/inventory.service';
import { MacDialogComponent } from 'src/app/core/layout/mac-dialog/mac-dialog.component';
import { PrinterInfoDialogComponent } from 'src/app/core/layout/printer-info-dialog/printer-info-dialog.component';
import { CreateDialogComponent } from 'src/app/core/layout/create-dialog/create-dialog.component';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';

@Component({
  selector: 'app-printer-warehouse',
  templateUrl: './printer-warehouse.component.html',
  styleUrls: ['./printer-warehouse.component.scss']
})
export class PrinterWarehouseComponent {
  Number(arg0: any): import("@angular/cdk/coercion").NumberInput {
    throw new Error('Method not implemented.');
  }
  private _unSubject$ = new Subject();
  runningPrinters: any = [];
  allProgress: any = []
  idArr: any = [];
  runningPrinters$ = new BehaviorSubject<any>([]);
  availablePrinters: any = [];
  printers: Printer[] = [];
  value$ = new BehaviorSubject<number>(0)
  value = 0;

  printerData: any;

  //data for unsubcribe
  data$ = interval(1000);
  dataSubscription!: Subscription;

  //for loading data
  showSpinner = false;
  constructor(
    private printerService: PrinterService,
    public router: Router,
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
    private inventoryService: InventoryService,
    private spinnerService: SpinnerService
  ) {

    this.spinnerService.spinner$.subscribe((data: boolean) => {
      setTimeout(() => {
        this.showSpinner = data ? data : false;
      });
    });
    this.spinnerService.showSpinner();
  }

  ngAfterViewInit() {
    this.getAllPrinters();
  }
  convertToNum(progress: String) {
    return Number(Number(progress != undefined ? progress : 30).toFixed(0));
  }

  ngAfterContentInit() {
    // this.getAllPrinters()
    this.dataSubscription = this.data$
      .subscribe((val) => {
        // console.log(this.idArr);
        this.printerService.getAllProgress(this.idArr).subscribe(
          res => {
            console.log('all progress ', res);
            this.allProgress = res.data;

            this.allProgress && this.allProgress.sort((a: any, b: any) => a.position < b.position ? -1 : 1).map(
              (item: any) => {
                if (item.progress == 100) {
                  this.getAllPrinters();
                  this.dataSubscription.unsubscribe();
                  this.openSnackBar("Printing process has done", 'Cancel');
                  window.location.reload();
                } else {

                }
              }
            );

          },
          err => {
            this.openSnackBar
          }

        );
      });

  }

  formatTime(seconds: number, id: String) {

    //set percent
    var percent = Number(this.allProgress.filter((item: any) => item.printerId == id)[0].progress);

    let result;
    let remainingSecs;


    remainingSecs = Number((seconds * (100 - percent) / 100).toFixed(0));

    if (remainingSecs >= 3600) {
      result = new Date(remainingSecs * 1000).toISOString()
        .slice(11, 19);
    } else {
      result = new Date(remainingSecs * 1000).toISOString()
        .slice(14, 19);

    }


    return result;
  }

  openModal() {
    let dialogRef = this.dialog.open(MacDialogComponent, {
      width: 'fit-content%',
      autoFocus: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result != undefined) {
        if (result.trim() == '') {
          this.snackbarService.open('Empty printer name', 'Hide')
        } else this.checkMACStatus(result)
      }


    })
  }
  checkMACStatus(key: string) {
    let isCancel = false;
    if (key == undefined) {
      isCancel = true
    }
    this.printerService.checkMAC(key).subscribe(
      res => {
        console.log(res.data);
        if (res.code != 200) {
          this.snackbarService.open(res.message, 'Cancel')
        }
        this.printerData = res.data;
        this.viewPrinterInfo(res.data, true);

      },
      err => {
        if (!isCancel) {
          this.snackbarService.open(err.error.message, 'Cancel')
        } else {
        }
      },
      () => { }
    )


  }
  openSnackBar(message: string, action: string) {
    this.snackbarService.open(message, action);
  }
  viewPrinterInfo(item?: any, isActivate?: boolean, isRunning?: boolean) {
    console.log('item', item);

    let printerInfo: any = item;
    let activationKey: string = item.activationKey;
    if (isRunning) {
      console.log('isrunning');
      console.log('filter', this.printers.filter((i: any) => i.activationKey == item.printerActivationKey));
      printerInfo = this.printers.filter((i: any) => i.activationKey == item.printerActivationKey)[0];
    }
    console.log(printerInfo);
    let dialogRef = this.dialog.open(PrinterInfoDialogComponent, {
      width: '35%',
      data: { printerInfo, isActivate, isRunning },
      autoFocus: true,

    });

    dialogRef.afterClosed().subscribe(result => {

      //activate printer here
      console.log(result);
      if (result) {
        let createDialogRef = this.dialog.open(CreateDialogComponent, {
          width: 'fit-content%',
          data: { title: 'New printer name dialog', btnContent: 'Create', newFolder: undefined },
          autoFocus: true,
        })
        createDialogRef.afterClosed().subscribe(dialogResult => {
          if (dialogResult != undefined) {
            if (dialogResult.trim() == '') {
              this.snackbarService.open('Empty printer name', 'Hide');
            } else {
              this.spinnerService.showSpinner();
              this.printerService.activatePrinter(activationKey, dialogResult).subscribe(
                res => {
                  window.location.reload();
                  this.openSnackBar("Activate successfully", 'Cancel');
                  this.spinnerService.hideSpinner();
                },
                err => {
                  this.openSnackBar("Activate failed", 'Cancel');
                  this.spinnerService.hideSpinner();
                },
                () => {
                  this.spinnerService.hideSpinner();
                }
              )
              // this.getAllPrinters();

            }
          } else this.snackbarService.open('Empty printer name');

        })

      }
    })
  }
  getAllPrinters() {
    this.printerService.getAllPrinters().subscribe(
      data => {
        console.log('all', data.data);
        this.printers = data.data != null ? data.data : [];
        this.printerService.getRunningPrinters().subscribe(
          res => {
            if (res.data == null) {
              console.log('null');
              this.availablePrinters = this.printers;
              this.runningPrinters = [];
            } else {
              this.runningPrinters = res.data.sort((a: any, b: any) => a.position < b.position ? -1 : 1);
              console.log('running', res.data);

              res.data && res.data.sort((a: any, b: any) => a.position < b.position ? -1 : 1).map(
                (i: any) => {
                  this.idArr.push(Object.assign({
                    printerId: i.id,
                    printerActivationKey: i.printerActivationKey,
                    position: i.position
                  }))
                }
              )
              let arrayRunningKeys = this.runningPrinters.map((i: any) => { return i.printerActivationKey })

              this.availablePrinters = this.printers.filter(({ activationKey }) => !arrayRunningKeys.includes(activationKey))

            }
          },
          err => {
            this.snackbarService.open(err.error.message);
            this.spinnerService.hideSpinner();

          },
          () => {
            this.spinnerService.hideSpinner();
          }

        )


      }, err => { }
    )


  }
  ngOnDestroy() {
    this.dataSubscription.unsubscribe();

  }


}
