<div class="login-page-wrapper">
  <div class="left-side-wrapper">
    <div class="header-wrapper">
      <div class="left-side-container">
        <div class="logo-wrapper">
          <img
            src="../../../assets/images/Logo.png"
            alt="Logo"
            routerLink="/"
          />
        </div>
        <div class="app-name-wrapper">Horizon Tek</div>
      </div>
    </div>
    <div class="body-wrapper">
      <form
        [formGroup]="loginForm"
        (ngSubmit)="onSubmit()"
        class="form-wrapper"
      >
        <div class="header">
          <div class="welcome">Forgot password</div>
          <div class="sub"></div>
          <h1></h1>

          <!-- <img src="../../../assets/images/Button.png" alt="" /> -->
          <!-- <div class="">{{ message }}</div> -->
          <div class="or"></div>
        </div>

        <input
          [ngClass]="{
            'invalid-input':
              loginForm.get('email')?.invalid &&
              loginForm.get('email')?.touched,
            'valid-input':
              loginForm.get('email')?.valid && loginForm.get('email')?.touched
          }"
          (focus)="error = false"
          type="text"
          name="email"
          id=""
          placeholder="email@gmail.com *"
          class="input"
          formControlName="email"
          (blur)="validateEmail()"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
        />
        <!-- <div
            *ngIf="submitted && f['email'].errors"
            class="invalid-feedback required-error"
          >
            <div *ngIf="f['email'].errors['required']">
              email is required
            </div>
          </div> -->
        <!-- <input
          [ngClass]="{
            'invalid-input':
              loginForm.get('password')?.invalid &&
              loginForm.get('password')?.touched,
            'valid-input':
              loginForm.get('password')?.valid &&
              loginForm.get('password')?.touched
          }"
          (focus)="error = false"
          type="password"
          name="password"
          id=""
          placeholder="Password *"
          class="input my-custom-level"
          formControlName="password"
          minlength="6"
          (blur)="validatePassword()"
        /> -->
        <!-- <div
            *ngIf="submitted && f['password'].errors"
            class="invalid-feedback required-error"
          >
            <div *ngIf="f['password'].errors['required']">
              Password is required
            </div>
          </div> -->
        <!-- <div class="forgot">Send verification code</div> -->

        <button
          [disabled]="loginForm.invalid"
          btnHighlight
          label="Send code"
          color="#ffffff"
          bgColor="#59BA6A"
          width="21rem"
          [isLoading]="isLoading"
          class="btn-wrapper"
        >
          Send code
        </button>
        <div class="link" routerLink="/login">
          Already have an account? <strong>Sign In</strong>
        </div>
      </form>
    </div>
  </div>
  <div class="right-side-wrapper">
    <img [src]="randomImage" alt="Cover image" />
  </div>
</div>
