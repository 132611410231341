import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { delay } from 'rxjs';
import { EditProfileDialogComponent } from 'src/app/core/layout/edit-profile-dialog/edit-profile-dialog.component';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  user!: User | null;
  showSpinner = false;

  uploadForm!: FormGroup;

  constructor(
    private jwtService: JwtService,
    public dialog: MatDialog,
    private authServie: AuthService,
    private snackbarService: SnackbarService,
    private spinnerService: SpinnerService,
    private _fb: FormBuilder,
    public router: Router

  ) {
    this.spinnerService.spinner$.subscribe((data: boolean) => {
      setTimeout(() => {
        this.showSpinner = data ? data : false;
      });
    });
    this.spinnerService.showSpinner();
    this.user = this.jwtService.getUser();
    this.getUserById();

  }
  ngOnInit() {
    this.uploadForm = this._fb.group({
      file: [''],
    });
  }

  handleFileInput(e: any) {
    const file: File = e.target.files[0];
    this.uploadForm.get('file')?.setValue(file)

  }
  onSubmit() {
    var formData: any = new FormData();
    let uploadFileName = this.uploadForm.get('file')?.value.name as String;
    formData.append('form_file', this.uploadForm.get('file')?.value);

    let res: any
    this.authServie.updateProfileAvatar(formData).subscribe(
      data => {
        res = data;
        console.log(res);
        if (res.code == 200) {
          this.snackbarService.open('Upload avatar successfully');
          delay(2000);

          window.location.reload();

        }
      },
      err => {

      })
  }

  getUserById() {
    const id: string = this.user?.id !== undefined ? this.user?.id : '';
    this.authServie.getUserById(id).subscribe(
      res => {
        this.user = res.data;
        this.spinnerService.hideSpinner()
      },
      err => {
        this.snackbarService.open('Something went wrong')
      },
      () => {

      }
    )
  }
  openDialog(isAccount?: boolean): void {
    let dialogRef = this.dialog.open(EditProfileDialogComponent, {
      width: isAccount ? 'max-content%' : 'max-content%',
      data: { title: isAccount ? 'Change password dialog' : 'Edit profile dialog', data: this.user, isAccount: isAccount },
      autoFocus: true,
      height: 'fit-content%',

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result != undefined) {
        if (isAccount && result) {
          let updatePassword = result;
          this.updatePassword(updatePassword)
        } else {
          let updateUser: User = {
            id: this.user?.id !== undefined ? this.user?.id : '',
            username: result.username,
            email: result.email,
            firstName: result.firstName,
            lastName: result.lastName,
            sex: result.sex,
            language: result.language
          }
          this.updateProfile(updateUser)
        }

      }

    })
  }

  updateProfile(editInfo: User) {
    this.spinnerService.showSpinner()

    this.authServie.updateProfile(editInfo).subscribe(
      res => {
        this.user = res.data
        this.snackbarService.open("Edit successfully");
      },
      err => {
        this.spinnerService.hideSpinner();
        this.snackbarService.open("Edit failed")
      },
      () => {
        this.spinnerService.hideSpinner();

      }
    )
  }
  updatePassword(body: any) {
    this.spinnerService.showSpinner()
    this.authServie.changePassword(body.oldPassword, body.newPassword).subscribe(
      res => {
        this.snackbarService.open("Edit successfully");
      },
      err => {
        this.snackbarService.open(err.error.message);
        this.spinnerService.hideSpinner();
      },
      () => {
        this.spinnerService.hideSpinner();
      }
    )
  }

}
