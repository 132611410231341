import { Injectable, Inject } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../layout/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  durationSeconds = 2;
  defaultActionMessage = 'Hide';
  defaultMessage = 'Snackbar default message';


  constructor(
    private _snackBar: MatSnackBar) {
  }


  error(message: string, action?: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message,
        action: action
      },
      panelClass: 'error-snackbar'
    });
  }

  success(message: string, action: string) {
    return this._snackBar.open(message, action, {
      panelClass: ['snackbar-success'], horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: this.durationSeconds * 1000

    });
  }

  info(message: string, action: string) {
    return this._snackBar.open(message, action, {
      panelClass: ['snackbar-info'], horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: this.durationSeconds * 1000

    });
  }

  open(message: string, action?: string) {
    return this._snackBar.open(message != undefined ? message : this.defaultMessage, action != undefined ? action : this.defaultActionMessage, {
      panelClass: ['snackbar-info'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: this.durationSeconds * 1000
    });
  }


}

