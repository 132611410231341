import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-breadcrum',
  templateUrl: './breadcrum.component.html',
  styleUrls: ['./breadcrum.component.scss']
})
export class BreadcrumComponent {
  @Input() content = ''; // decorate the property with @Input()
  @Input() isBack = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  onClickHandler() {
    this.onClick.emit();
  }


}
