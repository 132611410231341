<div *ngIf="!showSpinner" class="printer-warehouse-wrapper">
  <app-header></app-header>
  <app-breadcrum
    [isBack]="true"
    (onClick)="this.router.navigate(['/'])"
    [content]="'Printer Warehouse'"
  ></app-breadcrum>
  <div class="printer-wrapper">
    <div class="running-wrapper">
      <div class="wrapper-title">
        <div class="title">Running Printer</div>
        <div class="search-wrapper">
          <app-search></app-search>
        </div>
      </div>
      <div class="running-list" *ngIf="runningPrinters?.length > 0">
        <div
          class="p-item"
          (click)="viewPrinterInfo(item, false, true)"
          *ngFor="let item of runningPrinters"
        >
          <div class="center">
            <div class="p-img">
              <img src="../../../assets/images/printer.png" alt="" />
            </div>
            <div class="title">{{ item?.printerName }}</div>

            <div class="left-time">
              {{
                convertToNum(allProgress[item.position - 1].progress) < 1
                  ? "Heating..."
                  : formatTime(item?.estimatedTime, item?.id) + " to complete"
              }}

              <br />
            </div>
          </div>
          <div class="progress">
            <div class="bar">
              <mat-progress-bar
                mode="determinate"
                [value]="convertToNum(allProgress[item.position - 1].progress)"
              ></mat-progress-bar>
            </div>

            <div class="percent">
              {{ convertToNum(allProgress[item.position - 1].progress) + "%" }}
            </div>
          </div>
        </div>
      </div>
      <div class="not-found" *ngIf="runningPrinters?.length == 0">
        <div class="p-item">There is no running printer</div>
      </div>
      <div class="available-wrapper">
        <div class="wrapper-title">
          <div class="title">Available Printer</div>
          <div class="search-wrapper search-wrapper-btn">
            <app-search></app-search>
            <div class="button">
              <button
                class="btn btn-primary"
                color="primary"
                (click)="openModal()"
              >
                Add printer
              </button>
            </div>
          </div>
        </div>
        <div
          class="running-list available"
          *ngIf="availablePrinters?.length != 0"
        >
          <div
            class="p-item"
            (click)="viewPrinterInfo(p, false, false)"
            *ngFor="let p of availablePrinters"
          >
            <div class="center">
              <div class="p-img">
                <img src="../../../assets/images/printer.png" alt="" />
              </div>
              <div class="title">{{ p?.name }}</div>
              <!-- <div class="title">STATUS: {{ p?.status }}</div> -->
              <div class="left-time">Already to print</div>
            </div>
          </div>
        </div>
        <div class="not-found" *ngIf="availablePrinters?.length == 0">
          <div class="p-item">There is no available printer</div>
        </div>
      </div>
    </div>
  </div>
  <app-spinner *ngIf="showSpinner"></app-spinner>
</div>
