import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpErrorHandlerService } from './http-error-handler.service';
import { JwtService } from './jwt.service';
import { Observable } from 'rxjs';
import { environment } from 'environment';

@Injectable({
  providedIn: 'root'
})
export class FaceRegenerateService {

  constructor(
    private http: HttpClient,
    private jwtService: JwtService) {

  }
  regenerate(formData: any): Observable<any> {
    let API = `${environment.api}predict`
    return this.http.post<any>(API, formData)
  }
}
