<div *ngIf="!showSpinner" class="history-wrapper">
  <app-header></app-header>
  <app-breadcrum
    [isBack]="true"
    (onClick)="this.router.navigate(['/'])"
    [content]="'Printing History'"
  ></app-breadcrum>
  <div class="wrapper">
    <div class="body">
      <app-search (searchEvent)="getSearchVal($event)"></app-search>
      <div class="table-wrapper" *ngIf="historyList != null; else block">
        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" class="table-data">
            <!-- Position Column -->
            <ng-container matColumnDef="position">
              <th mat-header-cell *matHeaderCellDef>No.</th>
              <td mat-cell *matCellDef="let element">{{ element.position }}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="fileName">
              <th mat-header-cell *matHeaderCellDef>File Name</th>
              <td mat-cell *matCellDef="let element">{{ element.fileName }}</td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.activityCreatedAt }}
              </td>
            </ng-container>
            <ng-container matColumnDef="time">
              <th mat-header-cell *matHeaderCellDef>Printing Time</th>
              <td mat-cell *matCellDef="let element">
                {{ element.activityEstimatedTime }}
              </td>
            </ng-container>
            <ng-container matColumnDef="printer">
              <th mat-header-cell *matHeaderCellDef>Printer</th>
              <td mat-cell *matCellDef="let element">
                {{ element.printerName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="application">
              <th mat-header-cell *matHeaderCellDef>Application</th>
              <td mat-cell *matCellDef="let element">
                {{ element.fileApplication }}
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">
                {{ element.activityStatus }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <mat-paginator
            #paginator
            [hidePageSize]="true"
            showFirstLastButtons
            [length]="temp.length"
            [pageSizeOptions]="[10]"
            aria-label="select page"
          >
          </mat-paginator>
        </div>
      </div>
      <ng-template #block class="table-wrapper">0 history record</ng-template>
    </div>
  </div>
</div>
<app-spinner *ngIf="showSpinner"></app-spinner>
