import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-printing-center',
  templateUrl: './printing-center.component.html',
  styleUrls: ['./printing-center.component.scss']
})
export class PrintingCenterComponent {
  constructor(public router: Router) {

  }
}
