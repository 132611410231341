import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  @Input() isTransform: boolean = false;
  @ViewChild('wrapper') iconWrapper!: ElementRef;

  nativeEle = this.elementRef.nativeElement as HTMLDivElement;

  constructor(private elementRef: ElementRef) { };
  ngAfterViewInit(): void {

    if (this.isTransform) {

      this.iconWrapper.nativeElement.classList.add('transform');

    }
  }
}
