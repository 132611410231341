import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { PrinterService } from '../../services/printer.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-printer-info-dialog',
  templateUrl: './printer-info-dialog.component.html',
  styleUrls: ['./printer-info-dialog.component.scss']
})

export class PrinterInfoDialogComponent {

  printerInfo!: any;
  isActivate!: boolean;
  isRunning!: boolean;
  constructor(
    public dialogRef: MatDialogRef<PrinterInfoDialogComponent>,
    private printerService: PrinterService,
    private snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.printerInfo = data.printerInfo;
    this.isActivate = data.isActivate;
    this.isRunning = data.isRunning;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  addPrinter() {
    return true

  }

}
