import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent {
  @Input() props!: { label: string; bgColor: string; color: string };

  @Output() btnClick = new EventEmitter();


  label: String = '';
  color: String = '#000000'
  bgColor: String = '#f2f2f2';

  ngAfterViewInit() {
    this.label = this.props.label;
    this.bgColor = this.props.bgColor;
    this.color = this.props.color
  }



  clickHandler() {
    this.btnClick.emit();
  }
}
