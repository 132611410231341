<div class="dialog-wrapper">
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content>
    <form *ngIf="!isAccount" [formGroup]="form" (change)="onSubmit()">
      <div class="info-field">
        <div class="label">Username</div>
        <div class="input-wrapper">
          <input
            type="text"
            placeholder="Enter username"
            formControlName="username"
            (blur)="validateUsername()"
            [ngClass]="{
              'invalid-input':
                form.get('username')?.invalid && form.get('username')?.touched,
              'valid-input':
                form.get('username')?.valid && form.get('username')?.touched
            }"
            maxlength="25"
          />
        </div>
      </div>
      <div class="info-field">
        <div class="label">Email</div>
        <div class="input-wrapper">
          <input
            type="text"
            placeholder="Enter email"
            (blur)="validateEmail()"
            [ngClass]="{
              'invalid-input':
                form.get('email')?.invalid && form.get('email')?.touched,
              'valid-input':
                form.get('email')?.valid && form.get('email')?.touched
            }"
            formControlName="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
            maxlength="25"
          />
        </div>
      </div>
      <div class="info-field">
        <div class="label">First name</div>
        <div class="input-wrapper">
          <input
            type="text"
            placeholder="Enter first name"
            formControlName="firstName"
            [ngClass]="{
              'invalid-input':
                form.get('firstName')?.invalid &&
                form.get('firstName')?.touched,
              'valid-input':
                form.get('firstName')?.valid && form.get('firstName')?.touched
            }"
            maxlength="25"
            (blur)="validateFirstname()"
          />
        </div>
      </div>
      <div class="info-field">
        <div class="label">Last name</div>
        <div class="input-wrapper">
          <input
            maxlength="25"
            type="text"
            placeholder="Enter last name"
            formControlName="lastName"
            (blur)="validateLastname()"
            [ngClass]="{
              'invalid-input':
                form.get('lastName')?.invalid && form.get('lastName')?.touched,
              'valid-input':
                form.get('lastName')?.valid && form.get('lastName')?.touched
            }"
          />
        </div>
      </div>
      <!-- <div class="info-field">
        <div class="label">Sex</div>
        <div class="input-wrapper">
          <div class="input-wrapper">
            <select name="sex" formControlName="sex">
              <option value="{{ newData.sex }}" selected>
                {{ newData.sex == "" ? "None" : newData.sex }}
              </option>
              <option value="Female" [hidden]="newData.sex == 'Female'">
                Female
              </option>
              <option value="Male" [hidden]="newData.sex == 'Male'">
                Male
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="info-field">
        <div class="label">Language</div>
        <div class="input-wrapper">
          <select name="language" formControlName="language">
            <option
              value="{{ item }}"
              *ngFor="let item of languages"
              [selected]="newData.language == item"
            >
              {{ item }}
            </option>
          </select>
        </div>
      </div> -->
    </form>
    <form *ngIf="isAccount" [formGroup]="changePassForm" (change)="onSubmit()">
      <div class="info-field">
        <div class="label">
          Current password <span class="required">*</span>
        </div>
        <div class="input-wrapper">
          <div
            class="formField"
            [ngClass]="{
              'invalid-input':
                changePassForm.get('currentPassword')?.invalid &&
                changePassForm.get('currentPassword')?.touched,
              'valid-input':
                changePassForm.get('currentPassword')?.valid &&
                changePassForm.get('currentPassword')?.touched
            }"
          >
            <input
              class="no-border"
              matInput
              type="password"
              [type]="hide ? 'password' : 'text'"
              placeholder="Current password"
              formControlName="currentPassword"
              (blur)="validatePassword()"
              minlength="6"
              maxlength="25"
            />
            <button
              mat-icon-button
              matSuffix
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
            >
              <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="info-field">
        <div class="label">New password <span class="required">*</span></div>
        <div class="input-wrapper">
          <div
            class="formField"
            [ngClass]="{
              'invalid-input':
                changePassForm.get('newPassword')?.invalid &&
                changePassForm.get('newPassword')?.touched,
              'valid-input':
                !changePassForm.get('newPassword')?.invalid &&
                changePassForm.get('newPassword')?.touched &&
                changePassForm.get('currentPassword')?.value !=
                  changePassForm.get('newPassword')?.value
            }"
          >
            <input
              class="no-border"
              matInput
              type="password"
              [type]="hideSecond ? 'password' : 'text'"
              placeholder="New password"
              name="newFolder"
              formControlName="newPassword"
              (blur)="validateNewPassword()"
              minlength="6"
              maxlength="25"
            />
            <button
              class="show-pass-btn"
              mat-icon-button
              matSuffix
              (click)="hideSecond = !hideSecond"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hideSecond"
            >
              <mat-icon>{{
                hideSecond ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="info-field">
        <div class="label">
          Confirm new password <span class="required">*</span>
        </div>
        <div class="input-wrapper">
          <div
            class="wide-input formField"
            [ngClass]="{
              'invalid-input':
                changePassForm.get('confirmNewPassword')?.invalid &&
                changePassForm.get('confirmNewPassword')?.touched,
              'valid-input':
                changePassForm.get('confirmNewPassword')?.touched &&
                changePassForm.get('confirmNewPassword')?.valid &&
                changePassForm.get('confirmNewPassword')?.value ==
                  changePassForm.get('newPassword')?.value
            }"
          >
            <input
              class="no-border"
              type="password"
              placeholder="Confirm new password"
              name="newFolder"
              formControlName="confirmNewPassword"
              (blur)="validateConfirmNewPassword()"
              minlength="6"
              maxlength="25"
            />
            <button mat-icon-button [hidden]="true"></button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="dialog-action-btn">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button
      *ngIf="isAccount"
      [disabled]="
        changePassForm.invalid ||
        changePassForm.get('currentPassword')?.value ==
          changePassForm.get('newPassword')?.value ||
        changePassForm.get('confirmNewPassword')?.value !=
          changePassForm.get('newPassword')?.value
      "
      class="action-btn btn"
      mat-button
      [mat-dialog-close]="bodyData"
      cdkFocusInitial
    >
      Update
    </button>
    <button
      *ngIf="!isAccount"
      [disabled]="form.invalid"
      class="action-btn btn"
      mat-button
      [mat-dialog-close]="bodyData"
      cdkFocusInitial
    >
      Save
    </button>
  </div>
</div>
