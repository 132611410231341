import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandlerService, HandleError } from './http-error-handler.service';
import { Project } from '../models/project.model';
import { environment } from 'environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { JwtService } from './jwt.service';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
    })
};
export interface ResponseBody {
    code: number,
    data: any,
    message: string
}
@Injectable({
    providedIn: 'root'
})

export class InventoryService {

    projectUrl = environment.api + 'api/projects';  // URL to web api
    folderUrl = environment.api + 'api/folders';
    filetUrl = environment.api + 'api/files';
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandlerService,
        private jwtService: JwtService
    ) {
        this.handleError = httpErrorHandler.createHandleError('ProjectsService');
    }

    /** GET Projectes from the server */
    createProject(body: any): Observable<any> {
        return this.http.post<any>(this.projectUrl, body)
    }
    getAllProjects(): Observable<ResponseBody> {
        let userId = this.jwtService.getUser().id;
        return this.http.get<ResponseBody>(`${environment.api}api/users/${userId}/projects`)
    }
    getAllProjectsByUserId(id: string) {
        return this.http.get<ResponseBody>(environment.api + `api/${id}/projects`)
    }
    getProjectById(id: string): Observable<any> {
        return this.http.get<any>(this.projectUrl + "/" + id)
    }
    editProject(id: string, newName: string): Observable<any> {
        interface ReqBody {
            name: string,
            createdAt: string,
            userId: string
        };
        let body: ReqBody = {
            name: newName,
            createdAt: new Date().toISOString(),
            userId: this.jwtService.getUser().id

        };
        return this.http.put<any>(this.projectUrl + `/${id}`, body)
    }
    deleteProject(id: string): Observable<any> {
        return this.http.delete<any>(this.projectUrl + "/" + id)
    }
    createFolder(body: any): Observable<any> {
        return this.http.post<any>(this.folderUrl, body)
    }
    editFolder(id: string, newName: string, parentId: string | null, projectId: string | null): Observable<any> {
        interface ReqBody {
            parentId: string | null,
            projectId: string | null,
            name: string,
            createdAt: string
        };
        let body: ReqBody = {
            name: newName,
            createdAt: new Date().toISOString(),
            parentId: parentId,
            projectId: projectId

        };
        return this.http.put<any>(this.folderUrl + `/${id}`, body)
    }
    deleteFolder(id: string): Observable<any> {
        return this.http.delete<any>(this.folderUrl + "/" + id)
    }

    uploadFile(formData: any): Observable<any> {
        return this.http.post<any>(this.filetUrl + "/upload", formData)
    }
    deleteFile(fileId: string): Observable<any> {
        return this.http.delete<any>(this.filetUrl + "/" + fileId)

    }
    downloadFile(fileLink: string): void {
        const a = document.createElement('a')
        a.href = fileLink
        a.download = 'test.obj';

        a.click();
        a.remove()

    }

    updateFile(fileId: string, body: any): Observable<any> {
        return this.http.put<ResponseBody>(this.filetUrl + "/" + fileId, body)
    }

    getPrintingHistoryByUserId(): Observable<any> {
        let userId = this.jwtService.getUser().id;
        return this.http.get<ResponseBody>(`${environment.api}api/users/${userId}/printers/history`)

    }

}
