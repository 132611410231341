<div class="dialog-wrapper">
  <h1 mat-dialog-title>{{ title }}</h1>
  <div class="content" mat-dialog-content>
    <input
      type="text"
      [(ngModel)]="newData"
      placeholder="Enter name"
      name="newFolder"
      id=""
      [ngClass]="{
        'invalid-input':
          newData.trim() == '' && newData == oldData && isTouched,
        'valid-input': newData.trim() != '' && isTouched && newData != oldData
      }"
      (click)="isTouched = true"
    />
  </div>
  <div mat-dialog-actions class="dialog-action-btn">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button
      [disabled]="newData.trim() == '' || newData == oldData"
      class="action-btn"
      mat-button
      [mat-dialog-close]="newData"
      cdkFocusInitial
    >
      Update
    </button>
  </div>
</div>
