import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandlerService } from './http-error-handler.service';
import { environment } from 'environment';
import { BehaviorSubject, Observable, interval, take } from 'rxjs';
import { JwtService } from './jwt.service';


const PROGRESS_API = environment.api + `api/printers/94:E6:86:6D:CE:34/activities/progress`
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};
@Injectable({
  providedIn: 'root'
})
export class PrinterService {
  private handleError: HandleError;
  progress$!: Observable<any>;
  array$ !: BehaviorSubject<[]>;

  constructor(private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService, private jwtService: JwtService) {
    this.handleError = httpErrorHandler.createHandleError('PrintingService');
  }
  OnInit() {
  }

  getAllPrinters(): Observable<any> {
    let userId = this.jwtService.getUser().id;
    const PRINTER_API = environment.api + `api/users/${userId}/printers`;
    return this.http.get<any>(PRINTER_API, httpOptions)

  }
  getPrinterProgress(id: string): Observable<any> {
    const url = environment.api + `api/printers/${id}/activities/progress`
    return this.http.get<any>(url)
  }
  getAllProgress(idArr: []): Observable<any> {
    let userId = this.jwtService.getUser().id;
    const url = `${environment.api}api/users/${userId}/printers/active/progress`
    return this.http.post<any>(url, idArr)
  }
  checkMAC(key: string) {
    let userId = this.jwtService.getUser().id;
    const activatePrinterAPI = environment.api + `api/users/${userId}/printers/activate/${key}/status`;
    return this.http.post<any>(activatePrinterAPI, null)
  }
  activatePrinter(key: string, name: string) {
    let userId = this.jwtService.getUser().id;
    const activatePrinterAPI = environment.api + `api/users/${userId}/printers/activate/${key}`;
    return this.http.post<any>(activatePrinterAPI, { printerName: name })
  }
  getRunningPrinters(): Observable<any> {
    let userId = this.jwtService.getUser().id;
    return this.http.get<any>(`${environment.api}api/users/${userId}/printers/active`)

  }
  //
  getArray() {
    return this.array$.asObservable();
  }
}
