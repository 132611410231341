import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { HomePageComponent } from "./features/home-page/home-page.component";
import { LoginPageComponent } from "./features/login-page/login-page.component";
import { InventoryPageComponent } from "./features/inventory-page/inventory-page.component";
import { RegisterPageComponent } from "./features/register-page/register-page.component";
import { InventoryPrintingPageComponent } from "./features/inventory-printing-page/inventory-printing-page.component";
import { AuthGuard } from "./core/services/auth-guard.service";
import { PrinterWarehouseComponent } from "./features/printer-warehouse/printer-warehouse.component";
import { ErrorComponent } from "./features/error/error.component";
import { ProfileComponent } from "./features/profile/profile.component";
import { PrintingHistoryComponent } from "./features/printing-history/printing-history.component";
import { RegeneratePageComponent } from "./features/regenerate-page/regenerate-page.component";
import { ForgotPasswordComponent } from "./features/forgot-password/forgot-password.component";
import { AppHomeComponent } from "./features/home-subpage/app-home/app-home.component";
import { PrintingProfileComponent } from "./features/home-subpage/printing-profile/printing-profile.component";
import { UtilitiesComponent } from "./features/home-subpage/utilities/utilities.component";
import { UnderconstructionComponent } from "./features/underconstruction/underconstruction.component";
import { PrintingCenterComponent } from "./features/home-subpage/printing-center/printing-center.component";


const routes: Routes = [
  {
    path: "",
    component: HomePageComponent,
  },
  {
    path: "login",
    component: LoginPageComponent,
  },
  {
    path: "register",
    component: RegisterPageComponent
  },
  {
    path: "under-construction",
    component: UnderconstructionComponent,
  },
  {
    path: "app",
    component: AppHomeComponent
  },
  {
    path: "printing-profile",
    component: PrintingProfileComponent
  },
  {
    path: "printing-center",
    component: PrintingCenterComponent
  },
  {
    path: "utilities",
    component: UtilitiesComponent
  },
  {
    path: "inventory",
    component: InventoryPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "inventory/preview",
    component: InventoryPrintingPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "warehouse",
    component: PrinterWarehouseComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "printing-history",
    component: PrintingHistoryComponent,
    canActivate: [AuthGuard]
  },

  {
    path: "regenerate",
    component: RegeneratePageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "error",
    component: ErrorComponent,
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "**",
    redirectTo: "",

  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }