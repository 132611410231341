import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-mac-dialog',
  templateUrl: './mac-dialog.component.html',
  styleUrls: ['./mac-dialog.component.scss']
})
export class MacDialogComponent {
  form!: FormGroup;
  key: string = '';
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MacDialogComponent>,
    // @Inject(MAT_DIALOG_DATA) key: string,
  ) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  setValue(e: any) {
    this.key = e.target.value
  }
}
