<div class="input-wrapper">
  <div class="icon-wrapper">
    <mat-icon>search</mat-icon>
  </div>
  <input
    type="text"
    [(ngModel)]="searchVal"
    (keyup)="getSearch()"
    name=""
    id=""
  />
</div>
