<div class="login-page-wrapper">
  <div class="left-side-wrapper">
    <div class="header-wrapper">
      <div class="left-side-wrapper">
        <div class="logo-wrapper">
          <img
            src="../../../assets/images/Logo.png"
            alt="Logo"
            routerLink="/"
          />
        </div>
        <div class="app-name-wrapper" routerLink="/">Horizon Tek</div>
      </div>
    </div>
    <div class="body-wrapper">
      <form
        class="form-wrapper"
        [formGroup]="registerForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="header">
          <div class="welcome">Register</div>
          <div class="sub">Please enter your details</div>
        </div>
        <div
          class="input-wrapper"
          [ngClass]="{
            'invalid-input':
              registerForm.get('firstname')?.invalid &&
              registerForm.get('firstname')?.touched,
            'valid-input':
              registerForm.get('firstname')?.valid &&
              registerForm.get('firstname')?.touched
          }"
        >
          <input
            maxlength="25"
            type="text"
            name="firstname"
            placeholder="Firstname"
            class="input"
            formControlName="firstname"
            (blur)="validateFirstname()"
          />
        </div>
        <div
          class="input-wrapper"
          [ngClass]="{
            'invalid-input':
              registerForm.get('lastname')?.invalid &&
              registerForm.get('lastname')?.touched,
            'valid-input':
              registerForm.get('lastname')?.valid &&
              registerForm.get('lastname')?.touched
          }"
        >
          <input
            maxlength="25"
            type="text"
            name="lastname"
            placeholder="Lastname"
            class="input"
            formControlName="lastname"
            (blur)="validateLastname()"
          />
        </div>
        <div
          class="input-wrapper"
          [ngClass]="{
            'invalid-input':
              registerForm.get('username')?.invalid &&
              registerForm.get('username')?.touched,
            'valid-input':
              registerForm.get('username')?.valid &&
              registerForm.get('username')?.touched
          }"
        >
          <input
            maxlength="25"
            type="text"
            name="username"
            placeholder="Username"
            class="input"
            formControlName="username"
            (blur)="validateUsername()"
          />
        </div>
        <div
          class="input-wrapper"
          [ngClass]="{
            'invalid-input':
              registerForm.get('email')?.invalid &&
              registerForm.get('email')?.touched,
            'valid-input':
              registerForm.get('email')?.valid &&
              registerForm.get('email')?.touched
          }"
        >
          <input
            maxlength="25"
            type="text"
            name="email"
            placeholder="Email"
            class="input"
            formControlName="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
            (blur)="validateEmail()"
          />
        </div>
        <div
          class="input-wrapper"
          [ngClass]="{
            'invalid-input':
              registerForm.get('password')?.invalid &&
              registerForm.get('password')?.touched,
            'valid-input':
              registerForm.get('password')?.valid &&
              registerForm.get('password')?.touched
          }"
        >
          <input
            maxlength="25"
            type="password"
            [type]="hide ? 'password' : 'text'"
            name="password"
            placeholder="Password"
            class="input"
            formControlName="password"
            minlength="6"
            (blur)="validatePassword()"
          />
          <button
            class="show-pass-btn"
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
        </div>
        <div
          class="input-wrapper"
          [ngClass]="{
            'invalid-input':
              registerForm.get('confirmPass')?.touched ||
              (registerForm.get('confirmPass')?.invalid &&
                registerForm.get('confirmPass')?.value !=
                  registerForm.get('password')?.value),
            'valid-input':
              registerForm.get('confirmPass')?.touched &&
              registerForm.get('confirmPass')?.valid &&
              registerForm.get('confirmPass')?.value ==
                registerForm.get('password')?.value
          }"
        >
          <input
            maxlength="25"
            type="password"
            [type]="hideConfirmPass ? 'password' : 'text'"
            name="confirm-password"
            placeholder="Confirm password"
            class="input"
            formControlName="confirmPass"
            (blur)="validateConfirmPass()"
          />
          <button
            class="show-pass-btn"
            type="button"
            mat-icon-button
            matSuffix
            (click)="hideConfirmPass = !hideConfirmPass"
            [attr.aria-label]="'Hide password'"
          >
            <mat-icon>{{
              hideConfirmPass ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </div>

        <div class="btn-wrapper btn">
          <button
            class="btn"
            btnHighlight
            label="Sign Up"
            color="#ffffff"
            bgColor="#59BA6A"
            [isLoading]="isLoading"
            [disabled]="
              registerForm.invalid ||
              registerForm.get('confirmPass')?.value !=
                registerForm.get('password')?.value
            "
            mat-button
          ></button>
        </div>

        <div class="link" routerLink="/login">
          Already have an account? <strong>Sign In</strong>
        </div>
      </form>
    </div>
  </div>
  <div class="right-side-wrapper">
    <img [src]="randomImage" alt="Cover image" />
  </div>
</div>
