import { Directive, Input, ElementRef, AfterViewInit, HostListener, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({ selector: '[btnHighlight]' })

export class BtnHighlightDirective {
    // @Input() props!: { label: string; bgColor: string; color: string; width?: string; isLoading?: boolean };

    @Input() label!: string;
    @Input() bgColor!: string;
    @Input() color!: string;
    @Input() width?: string;
    @Input() isLoading?: any;
    @Input() isDisabled?: boolean;

    initBgColor: string = this.bgColor;
    nativeEle = this.elementRef.nativeElement as HTMLButtonElement;


    constructor(private elementRef: ElementRef, private renderer: Renderer2,
    ) {
    }

    ngDoCheck() {
        if (this.isLoading) {
            this.nativeEle.innerHTML = this.label + '...';
            this.nativeEle.style.backgroundColor = this.hexToRgbA(this.bgColor, 0.6);
            this.renderer.setProperty(this.nativeEle, 'disabled', this.isDisabled)
            this.nativeEle.disabled = this.isDisabled as boolean;
        } else {
            this.nativeEle.innerHTML = this.label;
            this.nativeEle.style.backgroundColor = this.bgColor
        }
        if (this.isDisabled) {
            this.renderer.setProperty(this.nativeEle, "disabled", this.isDisabled)
        }
    }

    hexToRgbA(hex: string, transparentOption?: number) {

        var c: any;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${transparentOption ?? 1})`;
        } else return hex;
        throw new Error('Bad Hex');
    }
    ngOnInit() {
        this.nativeEle.innerHTML = this.label;
        this.nativeEle.style.borderRadius = '12rem';
        this.nativeEle.style.padding = '0.5rem 1.5rem';
        this.nativeEle.style.letterSpacing = '0.07rem'
        this.nativeEle.style.fontWeight = '550';
        this.nativeEle.style.fontSize = '1rem';
        this.nativeEle.style.lineHeight = '1.2rem';

        this.nativeEle.style.backgroundColor = this.bgColor;
        this.nativeEle.style.borderColor = this.bgColor;
        this.nativeEle.style.color = this.color;
        this.nativeEle.style.fontWeight = 'bold';
        this.nativeEle.style.width = this.width ?? '100%';



        const child: HTMLElement = this.renderer.createElement('span');
        child.innerHTML = 'load'
        if (this.isLoading) {
            this.renderer.appendChild(this.nativeEle, child);
            // this.nativeEle.innerHTML = this.label + '...'
        } else {
            this.nativeEle.innerHTML = this.label
        }
    }

    //change style when hover
    // @HostListener('mouseenter') onMouseEnter() {

    //     this.bgColor = this.hexToRgbA(this.bgColor, 0.8).toString();
    // }

    //when you blur the bgColor return default bg
    // @HostListener('mouseout') onMouseLeave() {

    //     this.bgColor = this.hexToRgbA(this.bgColor, 1.2).toString();
    // }

    //onclick handler
    @HostListener('click', ['$event']) onClick($event: any) {
    }

}